import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { dataCzas, dataFormat } from '../../../functions/dataFormat';
import { sortByDate } from '../../../functions/utilities';


const HistoriaTransakcjiListaPlikow = (props) => { 

    const { przelewy, wspolnoty, wybranaWspolnotaId } = props;
    const dispatch = useDispatch();
    
    const [pliki, setPliki] = useState([]);
    const [nazwaFiltr, setNazwaFiltr] = useState("");

    useEffect( () => {

        let temp = przelewy
            .filter((item) => item.nazwa_pliku !== null && item.id_wspolnoty == wybranaWspolnotaId) // Usuwanie elementów z nazwa_pliku === null
            .map(({ nazwa_pliku, data_utworzenia, id_wspolnoty }) => ({
                nazwa_pliku,
                data_utworzenia,
                id_wspolnoty,
                wspolnota: wspolnoty?.find( w => w?.id == id_wspolnoty)?.name
            })) // Wybieranie tylko kluczowych pól
            .filter((item, index, self) => 
                index === self.findIndex((t) => 
                t.nazwa_pliku === item.nazwa_pliku &&
                t.data_utworzenia === item.data_utworzenia &&
                t.id_wspolnoty === item.id_wspolnoty
                )
            ); // Usuwanie duplikatów

            if (nazwaFiltr.length > 0)
                temp = temp?.filter( t => t?.nazwa_pliku.search(nazwaFiltr) >= 0 )

            setPliki(sortByDate(temp, "data_utworzenia"));

    }, [przelewy, wspolnoty, wybranaWspolnotaId, nazwaFiltr]);

    const handleFiltr = e => setNazwaFiltr( e.target.value );

    const tr = pliki?.map( (p, index) => (
        <tr key={index} className={ index % 2 ? "" : "g" }>
            <td
                style={{
                    textAlign: "center"
                }}
            >
                {p.nazwa_pliku}
            </td>
            <td
                style={{
                    textAlign: "center"
                }}
            >
                {dataFormat(p.data_utworzenia)}
            </td>
        </tr>
    ))

    return (
        <div className="pliki">
            <label htmlFor='znajdzPlik'>
                Odnajdź wgrany plik
            </label>
            <input 
                type="text"
                id="znajdzPlik"
                onChange={handleFiltr}
                value={nazwaFiltr}
            />
            <div
                style={{
                    "maxHeight": "300px",
                    "overflow": "auto"
                }}    
            >
                <table>
                    <thead>
                        <tr>
                            <th>
                                Nazwa pliku
                            </th>
                            <th>
                                Data wgrania
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {tr}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default HistoriaTransakcjiListaPlikow;
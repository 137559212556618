import React, { useEffect, useRef, useState } from "react";
import { data_z_dniem, dataFormat } from "../../../functions/dataFormat";  
import { adresPro } from "../../../functions/ulice";
import { zl } from "../../../functions/utilities";
import RozliczenieWydrukButtons from "./RozliczenieWydrukButtons";

const RozliczenieWydruk = props => {

    const { showPrint, mieszkaniec, lokal, lokale, ulice, licznik, tablicaCzynsze_ug, czyWydruk } = props;

    const handleModalClick = (event) => event.stopPropagation();
    const componentRef = useRef(null);

    const adres = adresPro(lokal.id, lokale, ulice);

    const [lista, setLista] = useState([]);
    const [naliczonaCiepla, setNaliczonaCiepla] = useState(0);
    const [nalizonaZimna, setNaliczonaZimna] = useState(0);
    const [naliczonyAboCw, setNaliczonyAboCw] = useState(0);

    useEffect( () => setLista( generujDaty(licznik) ), [licznik, tablicaCzynsze_ug]);

    useEffect( () => {
        let sumaCiepla = 0;
        let sumaZimna = 0;
        let sumaAboCw = 0;
        
        lista.forEach( l => {
            const udzial = tablicaCzynsze_ug?.find( t => t?.msc == l);
            if (udzial) {
                sumaCiepla += udzial?.ciepla_woda;
                sumaZimna += udzial?.zimna_woda;
                sumaAboCw += udzial?.abonament_cw;
            }
        });

        setNaliczonaCiepla(sumaCiepla);
        setNaliczonaZimna(sumaZimna);
        setNaliczonyAboCw(sumaAboCw);

    },[lista,tablicaCzynsze_ug]);

    const generujDaty = (licznik) => {
        const { okres_poczatek, okres_koniec } = licznik;
      
        const startDate = new Date(okres_poczatek);
        const endDate = new Date(okres_koniec);
      
        const result = [];
        const current = new Date(startDate);
      
        while (current <= endDate) {
          const month = String(current.getMonth() + 1).padStart(2, '0'); // Dodanie zer dla jednocyfrowych miesięcy
          const year = current.getFullYear();
          result.push(`${month}-${year}`);
      
          // Przejdź do następnego miesiąca
          current.setMonth(current.getMonth() + 1);
        }
      
        return result;
    };
    
    const saldo = (licznik?.zimna_koszt - nalizonaZimna) + ((licznik?.abonament_cw_cena * lokal?.metraz) - naliczonyAboCw) + (licznik?.ciepla_koszt - naliczonaCiepla);

    return (
        <div className="mieszkaniecWydruk wodaRozliczenieWydruk" onClick={showPrint}>
            <div className="modal" onClick={handleModalClick}>
                <div
                    className="A4"
                    style={{
                        width: '21cm',
                        height: '29.7cm',
                        backgroundColor: 'white',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                        padding: '15mm 15mm',
                        boxSizing: 'border-box',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between"
                    }} 
                    ref={componentRef}
                >
                    <section 
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <div>
                            <span>
                                <b>Administrator</b><br/>
                                Biuro Obsługi Nieruchomości<br/>
                                Mojsiewicz & Stanek s.c.<br/>
                                ul. Mieszka I 57/6<br/>
                                66-400 Gorzów Wielkopolski<br/>
                                t: 95 724 19 08<br/>
                                e: biuro@bonadministrator.pl
                            </span>
                        </div>
                        <div>
                            Gorzów Wlkp. {data_z_dniem()}
                        </div>
                    </section>

                    <section 
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            fontWeight: "500"
                        }}
                    >
                        <span>
                            Sz. P.<br/>
                            {mieszkaniec?.imie_nazwisko}<br/>
                            {adres}<br/>
                            66-400 Gorzów Wlkp.
                        </span>
                    </section>

                    <section
                        style={{justifyContent: "center"}}
                    >
                        <h4 style={{textAlign: "center"}}>
                            {`ROZLICZENIE ZUŻYCIA WODY ZA OKRES ${dataFormat(licznik.okres_poczatek)} - ${dataFormat(licznik.okres_koniec)}`}
                        </h4>
                    </section>
                    <section
                        style={{display: "flex"}}
                    >
                        <div>
                            {`Powierzchnia lokalu: ${lokal?.metraz} m²`}
                        </div>
                    </section>
                    <section 
                        className="rozliczenie-wody-wydruk-tabele"
                        style={{
                            display: "flex",
                            justifyContent: "space-around"
                        }}
                    >
                        <div style={{fontSize: "12px"}}>
                            <p style={{marginBottom: "5px"}}>
                                Stan liczników wody (w m³):
                            </p>
                            <table>
                                <thead>
                                    <tr>
                                        <th>

                                        </th>
                                        <th style={{border: "1px solid black"}}>
                                            Stan początkowy
                                        </th>
                                        <th style={{border: "1px solid black"}}>
                                            Stan bieżący
                                        </th>
                                        <th style={{border: "1px solid black"}}>
                                            Zużycie
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{border: "1px solid black"}}>
                                            zimna woda
                                        </td>
                                        <td style={{textAlign: "center", border: "1px solid black"}}>
                                            {licznik.zimna_stan_poczatek}
                                        </td>
                                        <td style={{textAlign: "center", border: "1px solid black"}}>
                                            {licznik.zimna_stan_koniec}
                                        </td>
                                        <td style={{textAlign: "center", border: "1px solid black"}}>
                                            {licznik.zimna_stan_koniec - licznik.zimna_stan_poczatek}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{border: "1px solid black"}}>
                                            ciepła woda
                                        </td>
                                        <td style={{textAlign: "center", border: "1px solid black"}}>
                                            {licznik.ciepla_stan_poczatek}
                                        </td>
                                        <td style={{textAlign: "center", border: "1px solid black"}}>
                                            {licznik.ciepla_stan_koniec}
                                        </td>
                                        <td style={{textAlign: "center", border: "1px solid black"}}>
                                            {licznik.ciepla_stan_koniec - licznik.ciepla_stan_poczatek}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{ fontSize: "12px", display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
                            <p style={{marginBottom: "5px"}}>
                                Koszt jednostkowy w okresie rozliczeniowym:
                            </p>
                            <table style={{ display: "block"}}>
                                <tbody>
                                    <tr>
                                        <td style={{border: "1px solid black"}}>
                                            zimna woda
                                        </td>
                                        <td style={{textAlign: "right", border: "1px solid black"}}>
                                            {zl(licznik?.zimna_cena)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{border: "1px solid black"}}>
                                            abonament cw
                                        </td>
                                        <td style={{textAlign: "right", border: "1px solid black"}}>
                                            {zl(licznik?.abonament_cw_cena)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{border: "1px solid black"}}>
                                            ciepła woda
                                        </td>
                                        <td style={{textAlign: "right", border: "1px solid black"}}>
                                            {zl(licznik.ciepla_cena)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <table
                        style={{fontSize: "12px"}}
                    >
                        <thead>
                            <tr>
                                <th>
                                    
                                </th>
                                <th style={{textAlign: "center", border: "1px solid black"}}>
                                    Naliczone <br/>stawki za okres 
                                </th>
                                <th style={{textAlign: "center", border: "1px solid black"}}>
                                    koszt zużycia<br/>w okresie <br/>rozliczeniowym
                                </th>
                                <th style={{textAlign: "center", border: "1px solid black"}}>
                                    rozliczenie
                                </th>
                                <th style={{textAlign: "center", border: "1px solid black"}}>
                                    razem
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{border: "1px solid black"}}>
                                    zimna woda
                                </td>
                                <td style={{textAlign: "center", border: "1px solid black"}}>
                                    {zl(nalizonaZimna)}
                                </td>
                                <td style={{textAlign: "center", border: "1px solid black"}}>
                                    {zl(licznik?.zimna_koszt)}
                                </td>
                                <td style={{textAlign: "center", border: "1px solid black"}}>
                                    {zl(licznik?.zimna_koszt - nalizonaZimna)}
                                </td>
                                <td className='saldo' style={{textAlign: "center", border: "1px solid black"}}>
                                    { saldo >= 0 ? "Nadpłata:" : "Niedopłata:" }
                                </td>
                            </tr>
                            <tr>
                                <td style={{border: "1px solid black"}}>
                                    abonament cw
                                </td>
                                <td style={{textAlign: "center", border: "1px solid black"}}>
                                    {zl(naliczonyAboCw)}
                                </td>
                                <td style={{textAlign: "center", border: "1px solid black"}}>
                                    {zl(licznik?.abonament_cw_cena * lokal?.metraz)}    
                                </td>
                                <td style={{textAlign: "center", border: "1px solid black"}}>
                                    {zl((licznik?.abonament_cw_cena * lokal?.metraz) - naliczonyAboCw)}
                                </td>
                                <td className='saldo' style={{textAlign: "center", border: "1px solid black"}}>
                                    { zl(saldo) } 
                                </td>
                            </tr>
                            <tr>
                                <td style={{border: "1px solid black"}}>
                                    ciepła woda
                                </td>
                                <td style={{textAlign: "center", border: "1px solid black"}}>
                                    {zl(naliczonaCiepla)}
                                </td>
                                <td style={{textAlign: "center", border: "1px solid black"}}>
                                    {zl(licznik?.ciepla_koszt)}
                                </td>
                                <td style={{textAlign: "center", border: "1px solid black"}}>
                                    {zl(licznik?.ciepla_koszt - naliczonaCiepla)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <section className="rozliczenie-wody-wydruk-wykonal">

                    </section>
                    <section 
                        className="rozliczenie-wody-wydruk-uwaga"
                        style={{ display: "flex", flexDirection: "column", fontSize: "12px" }}
                    >
                        <p>
                            Uwaga:
                        </p>
                        <p>
                            O nadpłatę można pomniejszyć kolejny wymiar opłat.<br/>
                            W przypadku salda ujemnego nadpłata pomniejsza zadłużenie.
                        </p>
                    </section>

                </div>

                <RozliczenieWydrukButtons
                    showPrint={showPrint}
                    componentRef={componentRef}
                />
                
            </div>

        </div>
    )
}

export default RozliczenieWydruk;
import React from 'react';
import { dataFormat } from '../../../functions/dataFormat';
import { zl } from '../../../functions/utilities';


const RozliczenieMieszkaniecStawki = props => {

    const { licznik } = props;
 
    return (
        <div className='rozliczenie-liczniki'>
            <table>
                <tbody>
                    <tr>
                        <td>
                            zimna woda
                        </td>
                        <td>
                            {zl(licznik?.zimna_cena)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            abonament cw
                        </td>
                        <td>
                            {zl(licznik?.abonament_cw_cena)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            ciepła woda
                        </td>
                        <td>
                            {zl(licznik.ciepla_cena)}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default RozliczenieMieszkaniecStawki;
import React from 'react';
import { dataFormat } from '../../../functions/dataFormat';
import { zl } from '../../../functions/utilities';


const RozliczenieMieszkaniecLiczniki = props => {

    const { licznik } = props;
 
    return (
        <div className='rozliczenie-liczniki'>
            <table>
                <thead>
                    <tr>
                        <th>

                        </th>
                        <th>
                            Stan początkowy
                        </th>
                        <th>
                            Stan bieżący
                        </th>
                        <th>
                            Zużycie
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            zimna woda
                        </td>
                        <td>
                            {licznik.zimna_stan_poczatek}
                        </td>
                        <td>
                            {licznik.zimna_stan_koniec}
                        </td>
                        <td>
                            {licznik.zimna_stan_koniec - licznik.zimna_stan_poczatek}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            ciepła woda
                        </td>
                        <td>
                            {licznik.ciepla_stan_poczatek}
                        </td>
                        <td>
                            {licznik.ciepla_stan_koniec}
                        </td>
                        <td>
                            {licznik.ciepla_stan_koniec - licznik.ciepla_stan_poczatek}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default RozliczenieMieszkaniecLiczniki;
import React, { useState, useEffect } from 'react';
import { dataFormat } from '../../../functions/dataFormat';
import { zl } from '../../../functions/utilities';
import RozliczenieMieszkaniecLiczniki from './RozliczenieMieszkaniecLiczniki';
import RozliczenieMieszkaniecStawki from './RozliczenieMieszkaniecStawki';
import RozliczenieMieszkaniecNaliczeniaRazem from './RozliczenieMieszkaniecNaliczeniaRazem';
import RozliczenieWydruk from './RozliczenieWydruk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'

const RozliczenieMieszkaniec = props => {

    const { mieszkaniec, liczniki, tablicaCzynsze_ug, lokal, lokale, ulice } = props;

    const [czyWydruk, setCzyWydruk] = useState(false);

    const showPrint = () => setCzyWydruk( prevState => !prevState );

    const listaLicznik = liczniki?.map( (licznik, i) => {

        return (
            <li
                key={i}
                className='ramka ramka-border-shadow ramka-wnetrze-ramki'
            >
                <h4>
                    {`Okres rozliczenia: ${dataFormat(licznik.okres_poczatek)} - ${dataFormat(licznik.okres_koniec)}`} 
                    <FontAwesomeIcon 
                        icon={faPrint} 
                        className='pointer'
                        onClick={showPrint}
                        style={{margin: "0 15px"}}
                    />
                </h4>
                <div
                    className='rozliczenie-mieszkaniec-dane'
                >
                    <p>
                        {`Powierzchnia lokalu: ${lokal?.metraz} m²`}
                    </p>
                    <div className='rozliczenie-liczniki-stawki'>
                        <RozliczenieMieszkaniecLiczniki
                            licznik={licznik}
                        />
                        <RozliczenieMieszkaniecStawki
                            licznik={licznik}
                        />
                    </div>
                    <div className='rozliczenie-naliczenia-razem'>
                        <RozliczenieMieszkaniecNaliczeniaRazem
                            licznik={licznik}
                            tablicaCzynsze_ug={tablicaCzynsze_ug?.temp_msc}
                            lokal={lokal}
                        />
                    </div>
                </div>
                {czyWydruk && (
                    <RozliczenieWydruk
                        showPrint={showPrint}
                        mieszkaniec={mieszkaniec}
                        lokal={lokal}
                        lokale={lokale}
                        ulice={ulice}
                        licznik={licznik}
                        tablicaCzynsze_ug={tablicaCzynsze_ug?.temp_msc}
                        czyWydruk={czyWydruk}
                    />
                )}
            </li>
        )
    })

    return (
        <div
            className='rozliczenie'
        >
            <ul>
                {listaLicznik}
            </ul>
        </div>
    )
}

export default RozliczenieMieszkaniec;
import React, { useState, useEffect } from 'react';
import SelectWspolnoty from '../SelectWspolnoty';
import ListaMieszkancow from '../ListaMieszkancow';
import RozliczenieZakresDat from './RozliczenieZakresDat';
import RozliczenieMieszkaniec from './RozliczenieMieszkaniec';
import { findMieszkanieId } from '../../../functions/ulice';


const Rozliczenie = props => {

    const { mieszkancy, grupyAkt, lokale, ulice, udzialyKonta, wspolnoty, activeWindow, liczniki, tablicaCzynsze } = props;

    const [wybranaWspolnota, setWybranaWspolnota] = useState(undefined);
    const [wybranyUdzialKonto, setWybranyUdzialKonto] = useState([]);
    const [wybranyMieszkaniec, setWybranyMieszkaniec] = useState([]);
    const [dataOd, setDataOd] = useState(null);
    const [dataDo, setDataDo] = useState(null);
    const [tablicaCzynsze_w, setTablicaCzynsze_w] = useState(null);
    const [tablicaCzynsze_u, setTablicaCzynsze_u] = useState(null);
    const [tablicaCzynsze_ug, setTablicaCzynsze_ug] = useState(null);
    const [mieszkanieId, setMieszkanieId] = useState("");

    useEffect( () => setWybranaWspolnota( wspolnoty?.length > 0 ? wspolnoty[0] : []) ,[wspolnoty]);

    useEffect( () => setTablicaCzynsze_w( tablicaCzynsze?.find( t => t?.id_wspolnoty == wybranaWspolnota?.id ) ) ,[tablicaCzynsze, wybranaWspolnota, ])
    useEffect( () => setTablicaCzynsze_u( tablicaCzynsze_w?.grupy?.find( t => t?.id_user == wybranyMieszkaniec?.id_user )), [tablicaCzynsze_w, wybranyMieszkaniec])
    useEffect( () => setTablicaCzynsze_ug( tablicaCzynsze_u?.grupy_udzial_msc?.find( t => t?.grupa_numer == wybranyUdzialKonto?.grupa_numer ) ,[tablicaCzynsze_u, wybranyUdzialKonto]));
    useEffect( () => {
        setMieszkanieId(
            findMieszkanieId(wybranyUdzialKonto, grupyAkt, lokale)
        )
    }, [tablicaCzynsze_ug, wybranyUdzialKonto, grupyAkt, lokale]);

    return (
        <div className='content woda'>
            <h3 className="nazwa">Generowanie rozliczeń</h3>
            <div>
                <div className="naglowek rozliczenie-daty ">
                    <SelectWspolnoty 
                        wybranaWspolnota={wybranaWspolnota}
                        setWybranaWspolnota={setWybranaWspolnota}
                        wspolnoty={wspolnoty}
                        setWybranyMieszkaniec={setWybranyMieszkaniec}
                        setWybranyUdzialKonto={setWybranyUdzialKonto}
                    />
                    <div className="cena">
                        <RozliczenieZakresDat
                            dataOd={dataOd}
                            setDataOd={setDataOd}
                            dataDo={dataDo}
                            setDataDo={setDataDo}
                        />
                    </div>
                </div>
                <div className="container">
                    <ListaMieszkancow 
                        wybranyMieszkaniec={wybranyMieszkaniec}
                        wybranyUdzialKonto={wybranyUdzialKonto}
                        setWybranyUdzialKonto={setWybranyUdzialKonto}
                        setWybranyMieszkaniec={setWybranyMieszkaniec}
                        wybranaWspolnota={wybranaWspolnota}
                        udzialyKonta={udzialyKonta}
                        grupyAkt={grupyAkt}
                        mieszkancy={mieszkancy}
                        lokale={lokale}
                        ulice={ulice}
                        activeWindow={activeWindow}
                    />
                    <div className="rightSide">
                        <RozliczenieMieszkaniec
                            mieszkaniec={wybranyMieszkaniec}
                            liczniki={liczniki?.filter( l => l.id_user == wybranyMieszkaniec?.id_user)}
                            tablicaCzynsze_ug={tablicaCzynsze_ug}
                            lokal={lokale?.find( l => l?.id == mieszkanieId)}
                            lokale={lokale}
                            ulice={ulice}
                        />
                        
                    </div>

                </div>

            </div>  

        </div>
    )
}

export default Rozliczenie;
import React, { useState, useEffect } from 'react';



const RozliczenieZakresDat = props => {

    const { dataOd, setDataOd, dataDo, setDataDo } = props;

    return (
        <>
        </>
    )
}

export default RozliczenieZakresDat;
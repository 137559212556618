import React, { useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Usterka from "./Usterka/Usterka.js";
import Loader from "../Loader.js";
import MieszkaniecMojeDane from "./MieszkaniecMojeDane.js";
import MieszkaniecGlosowanie from "./Glosowanie/MieszkaniecGlosowanie.js";
import Zarzad from "./Zarzad.js";
import WspolnotaRaportZbiorowy from "../Wspolnoty/RaportZbiorowy/WspolnotaRaportZbiorowy.js";
import { AuthContext } from "../../Context/AuthProvider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullhorn, faFileInvoiceDollar, faCheckToSlot } from '@fortawesome/free-solid-svg-icons'

import { 
    fetchWspolnoty, 
    fetchMieszkancy, 
    fetchMieszkancyAll,
    fetchPrzelewy, 
    fetchLokale, 
    fetchUlice, 
    fetchStawki, 
    fetchStawkiInd,
    fetchStawkiJednorazowe,
    fetchGrupy, 
    fetchGrupyAll,
    fetchWersje, 
    fetchUdzialyKonta,
    fetchUdzialyKontaAkt,
    fetchUsers,
    fetchPlanowaneZuzycie,
    fetchCred,
    fetchStawkiJednorazoweInd
  } from '../../Redux/Actions.js'; 

import { motion } from 'framer-motion';
import { wstepy } from '../../functions/motions';

import { getTablicaCzynsze2, getSaldo_w, getTablicaPrzelewy2 } from "../../functions/tablice.js";

const Mieszkaniec = () => {

    const { cred, user, userId } = useContext(AuthContext);

    const dispatch = useDispatch();

    const [mieszkaniec, setMieszkaniec] = useState({});
    const [wspolnota, setWspolnota] = useState({});
    const [thisUser, setThisUser] = useState(0);
    const [tablicaCzynsze, setTablicaCzynsze] = useState([]);
    const [tablicaCzynsze_g, setTablicaCzynsze_g] = useState([]);
    const [tablicaPrzelewy, setTablicaPrzelewy] = useState([]);
    const [tablicaPrzelewy_u, setTablicaPrzelewy_u] = useState([]);
    const [udzialyKonta_u, setUdzialyKonta_u] = useState([]);
    const [udzialyKonta_a, setUdzialyKonta_a] = useState([]);
    const [grupy_u, setGrupy_u] = useState([]);
    const [grupy_a, setGrupy_a] = useState([]);
    const [stawki_w, setStawki_w] = useState([]);
    const [stawkiJednorazowe_w, setStawkiJednorazowe_w] = useState([]);
    const [loaderOn, setLoaderOn] = useState(true);
    const [czyZarzad, setCzyZarzad] = useState(false);
    const [activeWindow, setActiveWindow] = useState("Moje dane");
    
    useEffect( () => {
        if ( mieszkaniec && wspolnota && thisUser && tablicaCzynsze && tablicaPrzelewy && stawki_w && stawkiJednorazowe_w )
            setLoaderOn(false)
    },[ mieszkaniec, wspolnota, thisUser, tablicaCzynsze, tablicaPrzelewy, stawki_w, stawkiJednorazowe_w]);

    const wspolnoty = useSelector( state  => state?.wspolnoty?.data);
    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const przelewy = useSelector( state => state?.przelewy?.data);
    const lokale = useSelector( state => state?.lokale?.data);
    const ulice = useSelector( state => state?.ulice?.data);
    const stawki = useSelector( state => state?.stawki?.data);
    const stawkiInd = useSelector( state => state?.stawkiInd?.data );
    const stawkiJednorazowe = useSelector( state => state?.stawkiJednorazowe?.data);
    const stawkiJednorazoweInd = useSelector( state => state?.stawkiJednorazoweInd?.data);
    const grupy = useSelector( state => state?.grupy?.data);
    const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);
    const planowaneZuzycie = useSelector( state => state?.planowaneZuzycie?.data);
    const users = useSelector(state => state?.users?.data);
    // console.log(users);

    const [saldo, setSaldo] = useState([]);

    const [activeGroup, setActiveGroup] = useState({idw: 0, grupa_numer: 1});
 
    useEffect( () => setUdzialyKonta_u( udzialyKontaAkt?.filter( u => u.id_user == thisUser?.id) ), [udzialyKontaAkt, thisUser]);

    useEffect( ( )=> {
        if (udzialyKonta_u?.length > 0) {
            setActiveGroup( { idw: udzialyKonta_u[0].id, grupa_numer: udzialyKontaAkt[0].grupa_numer } );
        }
    },[udzialyKonta_u]);

    useEffect( () => setUdzialyKonta_a( activeGroup?.idw > 0 ? udzialyKontaAkt?.find( u => u.id == activeGroup.idw) : [] ), [udzialyKontaAkt, activeGroup]);

    useEffect( () => setGrupy_u( grupy?.filter( g => g.id_user == thisUser?.id )), [grupy, thisUser, udzialyKonta_u]);

    useEffect( () => setGrupy_a( grupy_u?.filter( g => g.grupa_numer == activeGroup?.grupa_numer )) ,[grupy_u, activeGroup]);

    useEffect( ()=> {
      dispatch(fetchWspolnoty()); 
      dispatch(fetchMieszkancy()); 
      dispatch(fetchUsers());
      dispatch(fetchMieszkancyAll());
      dispatch(fetchPrzelewy()); 
      dispatch(fetchLokale()); 
      dispatch(fetchUlice()); 
      dispatch(fetchStawki()); 
      dispatch(fetchStawkiInd());
      dispatch(fetchStawkiJednorazowe());
      dispatch(fetchStawkiJednorazoweInd());
      dispatch(fetchGrupy()); 
      dispatch(fetchGrupyAll()); 
      dispatch(fetchUdzialyKonta());
      dispatch(fetchUdzialyKontaAkt());
      dispatch(fetchPlanowaneZuzycie());
    },[dispatch]);

    useEffect( () => dispatch(fetchCred(cred)), [cred])

    useEffect( ( ) => setThisUser( users?.find( u => u?.id == userId ) ),[userId, users]);

    useEffect( () => dispatch(fetchWersje(grupy)), [dispatch, grupy]);

    useEffect( () => setMieszkaniec( mieszkancy?.find( m => m.id_user == thisUser?.id) ), [mieszkancy, thisUser]);

    useEffect( () => setCzyZarzad( mieszkaniec?.czy_zarzad == 1 ? true : false), [mieszkaniec]);

     useEffect( () => setWspolnota( wspolnoty?.find( w => w.id == mieszkaniec?.id_wspolnoty) ), [wspolnoty, mieszkaniec]);

    useEffect( () => { 
      setTablicaCzynsze(getTablicaCzynsze2(wspolnoty, mieszkancy, grupy, [],udzialyKonta, udzialyKontaAkt, lokale, stawki, planowaneZuzycie, stawkiJednorazowe, stawkiInd, stawkiJednorazoweInd  /*, zaliczki */))
    },[wspolnoty, mieszkancy, grupy, udzialyKonta, lokale, stawki, planowaneZuzycie, stawkiJednorazowe, stawkiInd, stawkiJednorazoweInd  /*, zaliczki */])

    useEffect( () => setTablicaPrzelewy( getTablicaPrzelewy2(wspolnoty, udzialyKonta, przelewy)[0] ), [wspolnoty, udzialyKonta, przelewy])

    useEffect( () => {
      if (tablicaPrzelewy && tablicaPrzelewy){
        setSaldo( getSaldo_w(tablicaPrzelewy, tablicaCzynsze, wspolnoty) );
      }
    },[tablicaPrzelewy, tablicaCzynsze, wspolnoty]);

    useEffect( () => {
        const tablicaCzynsze_w = tablicaCzynsze?.find( t => t.id_wspolnoty == wspolnota?.id);
        const tablicaCzynsze_u = tablicaCzynsze_w?.grupy?.find( t => t.id_user == mieszkaniec?.id_user );
        setTablicaCzynsze_g( tablicaCzynsze_u?.grupy_udzial_msc?.find( g => g?.grupa_numer == activeGroup.grupa_numer )  );
    },[tablicaCzynsze, mieszkaniec, wspolnota, activeGroup]);

    useEffect( () => {
        const tablicaPrzelewy_w = tablicaPrzelewy?.find( t => t.id_wspolnoty == wspolnota?.id);
        setTablicaPrzelewy_u( tablicaPrzelewy_w?.grupy?.find( t => t.id_user == mieszkaniec?.id_user && t?.nr_grupyUdzial == activeGroup?.grupa_numer )?.przelewy);
    },[tablicaPrzelewy, wspolnota, mieszkaniec, activeGroup]);
    
    useEffect( () => setStawki_w( stawki?.find( s => s.id_wspolnoty == wspolnota?.id) ), [stawki, wspolnota]);

    useEffect( () => {
        setStawkiJednorazowe_w( 
            stawkiJednorazowe?.filter( s => s.id_wspolnoty == wspolnota?.id)
        )
    },[stawkiJednorazowe, wspolnota]);

    const handleMojeDane = () => setActiveWindow("Moje dane");
    const handleRozliczenie = () => setActiveWindow("Saldo wspólnoty");
    const handleRaportZbiorczy = () => setActiveWindow("Raport zbiorczy");
    const handleUsterka = () => setActiveWindow("Usterka");
    const handleGlosowanie = () => setActiveWindow("Głosowanie");

    const window = () => {
        switch(activeWindow) {
            case "Usterka": 
                return <Usterka
                    mieszkaniec={mieszkaniec}
                    wspolnota={wspolnota}
                />
            case "Moje dane":
                return <MieszkaniecMojeDane
                    mieszkaniec={mieszkaniec}
                    thisUser={thisUser}
                    ulice={ulice}
                    lokale={lokale}
                    udzialyKonta_u={udzialyKonta_u}
                    grupy_u={grupy_u}
                    tablicaCzynsze_g={tablicaCzynsze_g}
                    tablicaPrzelewy_u={tablicaPrzelewy_u}
                    stawki_w={stawki_w}
                    stawkiJednorazowe_w={stawkiJednorazowe_w}
                    grupy_a={grupy_a}
                    udzialyKonta_a={udzialyKonta_a}
                    activeGroup={activeGroup}
                    setActiveGroup={setActiveGroup}
                />
            case "Saldo wspólnoty":
                return <Zarzad />
            case "Raport zbiorczy":
                return <WspolnotaRaportZbiorowy 
                        czynszeWspolnota={tablicaCzynsze?.find( t => t?.id_wspolnoty == wspolnota?.id )?.grupy}
                        przelewyWspolnota={tablicaPrzelewy?.find( t => t?.id_wspolnoty == wspolnota?.id)?.grupy}
                    />
            case "Głosowanie":
                return <MieszkaniecGlosowanie
                    wspolnota={wspolnota}
                    mieszkaniec={mieszkaniec}
                />
        }

    }

    return (
        <div className="mieszkaniec">
            <Loader
                stan={loaderOn}
            />
            <div className="buttons">
                <button onClick={handleMojeDane}>
                    <FontAwesomeIcon icon={faFileInvoiceDollar} />
                    {`  `}
                    Moje dane
                </button>
                {
                    czyZarzad ? (
                        <>
                            <button onClick={handleRozliczenie}>Saldo Wspólnoty</button>
                            <button onClick={handleRaportZbiorczy}>Raport zbiorczy</button>
                        </>
                    ) : <></>
                }
                <button onClick={handleUsterka}>
                    <FontAwesomeIcon icon={faBullhorn} />
                    {`  `}
                    Zgłoś usterkę
                </button>
                {/* <button onClick={handleGlosowanie}>
                    <FontAwesomeIcon icon={faCheckToSlot} />
                    {`  `}
                    Głosowanie
                </button> */}
            </div>
            { window() }
            
        </div>

    )
}

export default Mieszkaniec;
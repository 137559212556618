import React from "react";

import { wstepy } from '../../../functions/motions';



const GrupyInstrukcja = () => {


    return (
        <div 
            className="instrukcja"
            variants={wstepy}
            initial="initial"
            animate="animate"
        >
            <p>
                Grupy
            </p>
            <span>
                Jedna grupa (np. "grupa numer: 2") może wystąpić u danego mieszkańca kilka razy, jeśli była zmiana liczby mieszkańców lub numeru konta. Wówczas "data od" oznacza zakres od kiedy ma to obowiązywać. 
                Np. od 1.07 czynsz liczony dla jednego mieszkańca a od 1.10 ma być liczony dla 2 mieszkańców.
            </span>
            <p>
                Lokale
            </p>
            <span>
                W grupach są lokale. "Data od" przy lokalu oznacza datę, od którego ma być liczony czynsz w danej grupie. Dlatego jeśli "grupa numer: 2" występuje dwa razy z datami od 1.07 i 1.10, "data od" lokalu
                powinna być w obu "1.07" (jeśli lokal jest w grupie od dnia jej założenia). <u>Jeśli "data od" lokalu jest późniejsza niż najwcześniejsza "data od" grupy</u> to znaczy, że lokal został dopisany później.
                Np. jeśli "grupa numer: 2" ma "data od": 1.08, a "data od" lokalu to 1.09 to znaczy, że czynsz za lokal w podsumowaniach i zbiorówce będzie liczony od 1.09. 
            </span>
            <p
                className="red"
            >
                Duble
            </p>
            <span>
                Jedna grupa może wystąpić tylko raz z jedną datą "data od". Jeśli jedna grupa występuje dwa razy z Data od: 1.07, w jedej jest 1 mieszkaniec a w drugiej 2, wybierze jedną z nich. 
                Takie duble są zaznaczone na czerwono. Wówczas należy przejść do szczegółow takiej grupy i jedną z nich usunąć (w "Historia zmian").
            </span>
        </div>
    )
}

export default GrupyInstrukcja;
import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import axios from "axios";

import { objIsNotEmpty, z, zl } from "../../../functions/utilities.js";
import { obliczWode } from "../../../functions/obliczCzynsz.js";
import StanLicznikowFormularzInput from './StanLicznikowFormularzInput';

import { 
  fetchOdczyty,
} from '../../../Redux/Actions.js'; 

const StanLicznikowFormularz = (props ) => {

  const { mode, setMode, loadedLicznik, wybranaWspolnota, wybranyMieszkaniec, mieszkancy, stawki, cieplaWodaCena, zimnaWodaCena, abonamentCwCena, rozliczanieWody, 
    setWybranyMieszkaniec, setWybranyUdzialKonto } = props;
  
  const dispatch = useDispatch();

  const [okresPoczatek, setOkresPoczatek] = useState();
  const [okresKoniec, setOkresKoniec] = useState();

  const [cieplaZuzycie, setCieplaZuzycie] = useState( /*objIsNotEmpty(loadedLicznik) ? loadedLicznik.ciepla_zuzycie :*/ 0 );
  const [zimnaZuzycie, setZimnaZuzycie] = useState( /*objIsNotEmpty(loadedLicznik) ? loadedLicznik.zimna_zuzycie :*/ 0 );

  const [cieplaKoszt, setCieplaKoszt] = useState( /*objIsNotEmpty(loadedLicznik) ? loadedLicznik.ciepla_koszt :*/ 0 );
  const [zimnaKoszt, setZimnaKoszt] = useState( /*objIsNotEmpty(loadedLicznik) ? loadedLicznik.zimna_koszt :0*/ );

  const [zimnaPoczatek, setZimnaPoczatek] = useState( /*objIsNotEmpty(loadedLicznik) ? loadedLicznik.zimna_stan_poczatek :*/ 0 );
  const [zimnaKoniec, setZimnaKoniec] = useState( /*objIsNotEmpty(loadedLicznik) ? loadedLicznik.zimna_stan_koniec :*/ 0 );

  const [cieplaPoczatek, setCieplaPoczatek] = useState( /*objIsNotEmpty(loadedLicznik) ? loadedLicznik.ciepla_stan_poczatek :*/ 0 );
  const [cieplaKoniec, setCieplaKoniec] = useState( /*objIsNotEmpty(loadedLicznik) ? loadedLicznik.zimna_stan_koniec :*/ 0 );
  
  const onSubmit = (  ) => {
    if (mode == "new") 
      addData( [] );
    else if (mode == "edit")
      editData([]);

  }

  const addData = async (values) => {
    console.log(values)
    try { 
      // Wysyłamy dane na serwer
      await axios.post('/api/odczyt/dodaj', {
        id_wspolnoty: wybranaWspolnota.id,
        id_user: wybranyMieszkaniec.id_user,
        okres_poczatek: okresPoczatek,
        okres_koniec: okresKoniec,
        ciepla_stan_poczatek: cieplaPoczatek,
        ciepla_stan_koniec: cieplaKoniec,
        ciepla_zuzycie: cieplaZuzycie,
        ciepla_koszt: cieplaKoszt,
        zimna_stan_poczatek: zimnaPoczatek,
        zimna_stan_koniec: zimnaKoniec,
        zimna_zuzycie: zimnaZuzycie,
        zimna_koszt: zimnaKoszt,
        ciepla_cena: cieplaWodaCena,
        zimna_cena: zimnaWodaCena,
        abonament_cw_cena: abonamentCwCena
        
      })
        .then(response => {
          dispatch(fetchOdczyty());  
          setZimnaPoczatek(0);
          setZimnaKoniec(0);
          setCieplaPoczatek(0);
          setCieplaKoniec(0);
          setOkresPoczatek(0);
          setOkresKoniec(0);
          setMode("new");
          setCieplaZuzycie(0);
          setZimnaZuzycie(0);
          setCieplaKoszt(0);
          setZimnaKoszt(0);
        });
      // Po udanym dodaniu danych możesz wykonać odpowiednie akcje, np. wyczyść formularz



    } catch (error) {
      console.error('Błąd podczas dodawania danych:', error);
    }
  };

  const editData = async (values) => {
    axios.post("/api/odczyt/edytuj", { 
      id: loadedLicznik.id,
      okres_poczatek: values.okres_poczatek == '' ? null : values.okres_poczatek,
      okres_koniec: values.okres_koniec == '' ? null : values.okres_koniec,
      ciepla_stan_poczatek: cieplaPoczatek,
      ciepla_stan_koniec: cieplaKoniec,
      ciepla_zuzycie: cieplaZuzycie,
      ciepla_koszt: cieplaKoszt,
      zimna_stan_poczatek: zimnaPoczatek,
      zimna_stan_koniec: zimnaKoniec,
      zimna_zuzycie: zimnaZuzycie,
      zimna_koszt: zimnaKoszt
    })
      .then(response => {
        dispatch(fetchOdczyty()); 
      })
  };

  const zimnaPoczatekChange = e => setZimnaPoczatek(e.target.value);
  const zimnaKoniecChange = e => setZimnaKoniec(e.target.value);
  const cieplaPoczatekChange = e => setCieplaPoczatek(e.target.value);
  const cieplaKoniecChange = e => setCieplaKoniec(e.target.value);
  const okresPoczatekChange = e => setOkresPoczatek(e.target.value);
  const okresKoniecChange = e => setOkresKoniec(e.target.value);

  // useEffect( () => {
  //   setZimnaPoczatek( loadedLicznik.zimna_stan_poczatek );
  //   setZimnaKoniec( loadedLicznik.zimna_stan_koniec );
  //   setCieplaPoczatek( loadedLicznik.ciepla_stan_poczatek );
  //   setCieplaKoniec( loadedLicznik.ciepla_stan_koniec );
  // },[loadedLicznik])

  useEffect( () => {

    const kosztWody = obliczWode( cieplaZuzycie, zimnaZuzycie, cieplaWodaCena, zimnaWodaCena, rozliczanieWody );

    setZimnaKoszt( kosztWody?.zimna );
    setCieplaKoszt( kosztWody?.ciepla );

  },[cieplaZuzycie, zimnaZuzycie]);

  useEffect( () => {
    setCieplaZuzycie(cieplaKoniec > 0 ? cieplaKoniec - cieplaPoczatek : 0); 
  },[cieplaPoczatek, cieplaKoniec]);

  useEffect( () => {
    setZimnaZuzycie( zimnaKoniec > 0 ? zimnaKoniec - zimnaPoczatek : 0);
  },[zimnaPoczatek, zimnaKoniec]);
 
  return (
    <div className="formularz">

      <p>{mode == "new" ? `Dodaj nowy okres dla: ${wybranyMieszkaniec?.imie_nazwisko}` : `Edytuj okres u ${wybranyMieszkaniec?.imie_nazwisko}`}</p>

      <StanLicznikowFormularzInput
        label="Początek okresu:"
        type="date"
        state={okresPoczatek}
        onChange={okresPoczatekChange}
      />
      <StanLicznikowFormularzInput
        label="Stan licznika wody ciepłej:"
        type="number"
        state={cieplaPoczatek}
        onChange={cieplaPoczatekChange}
      />
      <StanLicznikowFormularzInput
        label="Stan licznika wody zimnej:"
        type="number"
        state={zimnaPoczatek}
        onChange={zimnaPoczatekChange}
      />
      <StanLicznikowFormularzInput
        label="Koniec okresu:"
        type="date"
        state={okresKoniec}
        onChange={okresKoniecChange}
      />
      <StanLicznikowFormularzInput
        label="Stan licznika wody ciepłej:"
        type="number"
        state={cieplaKoniec}
        onChange={cieplaKoniecChange}
      />
      <StanLicznikowFormularzInput
        label="Stan licznika wody zimnej:"
        type="number"
        state={zimnaKoniec}
        onChange={zimnaKoniecChange}
      />




        <>
          <div className="wiersz zuzycie">
            <div className="label">Zużycie wody ciepłej:</div>
            <div className="value">{cieplaZuzycie + " m3"}</div>
            <div className="value">{cieplaKoszt + " zł"}</div>
          </div>
          
          <div className="wiersz zuzycie">  
            <div className="label">Zużycie wody zimnej:</div>
            <div className="value">{zimnaZuzycie + " m3"}</div>
            <div className="value">{zimnaKoszt + " zł"}</div>
          </div>
        </>

      <button 
        onClick={onSubmit}
      >
        Zapisz
      </button>
      {/* { mode == "new" ? (
        // <button type='reset'>Wyczyść</button>
      ) : ""} */}
      

    </div>
  )
}

export default StanLicznikowFormularz;
import { obliczCzynsz2 } from './obliczCzynsz.js'
import { addOneDay, dataFormatMsc, rrrrmmdd_to_mm_rrrr, dodajMiesiac, ostatniDzienMiesiaca, rrrr_mm_dd_to_mm_rrrr } from "./dataFormat.js";
import { zera, NaNtoZero } from "./utilities.js";


function findGrupaUdzial(wybranaDataTekst, tablicaObiektow) {

    const wybranaData = new Date(wybranaDataTekst);
  
    // Sortowanie tablicy obiektów według daty początkowej
    tablicaObiektow.sort((a, b) => new Date(a.data_od) - new Date(b.data_od));
  
    for (let i = 0; i < tablicaObiektow.length; i++) {
      const dataOd = new Date(tablicaObiektow[i].data_od);
      let dataDo;
  
      if (i + 1 < tablicaObiektow.length) {
        dataDo = new Date(tablicaObiektow[i + 1].data_od);
      } else {
        // Jeżeli to ostatni obiekt, ustawiamy dataDo na bardzo odległą datę w przyszłości
        dataDo = new Date('9999-12-31T23:59:59.999Z');
      }
  
      // Sprawdzanie, czy wybrana data mieści się w okresie obowiązywania obiektu
      if (wybranaData >= dataOd && wybranaData < dataDo) {
        return tablicaObiektow[i].id; // Zwróć id obiektu
      }
    }
  
    return null; // Zwróć null, jeśli żaden obiekt nie pasuje
}

const findStawka = (targetDate, stawki) => {
  // Konwersja stringa targetDate na obiekt Date
  const target = new Date(targetDate);

  // Filtrowanie cenników, które zaczynają obowiązywać przed lub w dniu target
  const validCenniki = stawki?.filter(cennik => {
    const dataOd = new Date(cennik.data_od);
    return dataOd <= target;
  });

  // Sortowanie cenników od najnowszego do najstarszego na podstawie data_od
  validCenniki?.sort((a, b) => {
    const dataA = new Date(a.data_od);
    const dataB = new Date(b.data_od);
    return dataB - dataA;  // Sortowanie malejące
  });

  // Zwracanie ID pierwszego cennika w posortowanej tablicy, jeśli istnieje
  return validCenniki?.length > 0 ? validCenniki[0].id : null;
}

const findZuzycie = (data, planowaneZuzycie, grupa) => {

    const idUser = grupa?.id_user;
    const zuzyciaUser = planowaneZuzycie?.filter( p => p?.id_user == idUser && p?.grupa_numer == grupa?.grupa_numer);

    const targetDate = new Date(data);
    let closestObject = null;

    for (const obj of zuzyciaUser) {
        const objDate = new Date(obj.data_od);

        if (objDate <= targetDate && (!closestObject || objDate > new Date(closestObject.data_od))) {
            closestObject = obj;
        }
    }

    return closestObject;
}
 
function liczbaDniWMiesiacu(data) {
    // const data = new Date(dataTekst);
    const rok = data.getFullYear();
    const miesiac = data.getMonth() + 1; // getMonth zwraca miesiąc od 0 (styczeń) do 11 (grudzień), dodajemy 1
  
    // Utworzenie daty z dniem ustawionym na 0 zwróci ostatni dzień poprzedniego miesiąca, więc tu dla miesiąca +1
    const ostatniDzien = new Date(rok, miesiac, 0);
  
    return ostatniDzien.getDate(); // getDate() zwróci dzień miesiąca, czyli liczba dni danego miesiąca
}

function findJednorazowe(stawkiJednorazowe_w,data) {
    if (stawkiJednorazowe_w?.length === 0)
        return { dod_1_kwota: 0, dod_2_kwota: 0, dod_3_kwota: 0 };
    else {
        return stawkiJednorazowe_w?.find( s => s.msc == dataFormatMsc(data) );
    }

}

export const getTablicaCzynsze2 = (wspolnoty, mieszkancy, grupy, grupyUniq, grupyUdzialy, grupyUdzialyAkt, lokale, stawki, planowaneZuzycie, stawkiJednorazowe, stawkiInd, stawkiJednorazoweInd /*, zaliczki*/) => {

     const wynik = wspolnoty?.map( wspolnota => {
       
        const stawki_w = stawki?.filter( stawka => stawka.id_wspolnoty == wspolnota.id);
        const stawkiJednorazowe_w = stawkiJednorazowe?.filter( stawka => stawka.id_wspolnoty == wspolnota?.id);
        const mieszkancy_w = mieszkancy?.filter( mieszkaniec => mieszkaniec.id_wspolnoty == wspolnota.id);
        const grupyUdzialyAkty_w = grupyUdzialyAkt?.filter( g => g?.id_wspolnoty == wspolnota?.id);

        const grupyNoty = grupyUdzialyAkty_w
            .filter(grupa => {
                const mieszkaniec = mieszkancy.find(m => m.id_user === grupa.id_user);
                return mieszkaniec && mieszkaniec.czy_nota === "1";
            })
            .map((grupa, index) => ({
                id: grupa.id,
                grupa_numer: grupa.grupa_numer,
                id_user: grupa.id_user,
                id_wspolnoty: grupa.id_wspolnoty,
                czy_usuniety: grupa.czy_usuniety,
                notaNr: index + 1
            }));

        const mieszkancy_temp = mieszkancy_w?.map( mieszkaniec => {

            const grupy_m = grupy?.filter( grupa => grupa.id_user == mieszkaniec.id_user)

            const grupy_dni = grupy_m?.map( grupa => {

                const grupyUdzialy_m = grupyUdzialy?.filter( grupaU => grupaU.id_user == mieszkaniec.id_user && grupaU?.grupa_numer == grupa?.grupa_numer );

                const dataStart = new Date(grupa.data_od);
                
                // const dataKoniec = grupa?.data_do == null ? ostatniDzienMiesiaca(dodajMiesiac(new Date())) : new Date(grupa?.data_do);
                const today = new Date();
                const dataKoniec = grupa?.data_do == null ? new Date(today.getFullYear(), today.getMonth() + 2, 0) : new Date(grupa?.data_do);

                const tablicaDni = [];
                while (dataStart <= dataKoniec) {
                    const year = dataStart.getFullYear();
                    const month = String(dataStart.getMonth() + 1).padStart(2, '0'); // Dodanie zera dla miesięcy < 10
                    const day = String(dataStart.getDate()).padStart(2, '0'); // Dodanie zera dla dni < 10
                
                    // Zapisz datę w formacie RRRR-MM-DD
                    tablicaDni.push(`${year}-${month}-${day}`);
                
                    // Przejdź do następnego dnia
                    dataStart.setDate(dataStart.getDate() + 1);
                }

                const lokal = lokale?.filter( lokal => lokal.id == grupa.id_lokal)[0];

                const temp_dni = [];

                tablicaDni?.forEach( dataT => {
                    const data = new Date(dataT)
                    const id_stawki_na_dzien = findStawka(data,stawki_w);
                    const liczbaDniMsc = liczbaDniWMiesiacu(data);

                    const stawkiInd_g = stawkiInd?.filter( s => s?.id_user == mieszkaniec?.id_user && s?.grupa_numer == grupa?.grupa_numer)
                    
                    const id_stawkiInd_na_dzien = findStawka(data, stawkiInd_g);
                    const stawki_na_dzien = stawki?.find( stawka => stawka.id == id_stawki_na_dzien);
                    const stawkiInd_na_dzien = stawkiInd_g?.find( stawka => stawka.id == id_stawkiInd_na_dzien);
                    const planowane_zuzycie_na_dzien = findZuzycie(data, planowaneZuzycie, grupa);

                    const id_grupaUdzial_na_dzien = findGrupaUdzial(data,grupyUdzialy_m);
                    const liczba_domownikow = grupyUdzialy_m?.find( grupa => grupa.id == id_grupaUdzial_na_dzien)?.liczba_domownikow;
                    const jednorazowe_na_dzien = findJednorazowe(stawkiJednorazowe_w,data);

                    const czynsz_na_dzien = obliczCzynsz2(liczba_domownikow, lokal,stawki_na_dzien,liczbaDniMsc, planowane_zuzycie_na_dzien, jednorazowe_na_dzien, stawkiInd_na_dzien);                    

                    temp_dni.push(
                        {
                            // dzien: data.toISOString().split('T')[0],
                            dzien: new Date(data),
                            czynsz_na_dzien: czynsz_na_dzien,
                        }
                    ); 
                })


                return {
                    id_grupa: grupa.id,
                    grupa_numer: grupa.grupa_numer,
                    temp_dni: temp_dni
                }
            })

            // if (mieszkaniec?.id_user == 70) {
            //     console.log(grupy_dni)
            // }
            
            const grupy_msc = grupy_m?.map( grupa => {

                const dataStart = new Date(addOneDay(grupa.data_od));
                
                const tablicaMiesiecy = [];
                let aktualnaData = new Date(dataStart.getFullYear(), dataStart.getMonth(), 1);
                const dataMiesiacPoObecnej2 = dodajMiesiac(new Date())

                while (aktualnaData <= dataMiesiacPoObecnej2) {
                    tablicaMiesiecy.push(dataFormatMsc(aktualnaData));
                    aktualnaData = dodajMiesiac(aktualnaData);
                }
              
                const temp_msc = [];
                let eksploatacja = 0;
                let eksploatacja_podstawa;
                let garaz = 0;
                let garaz_podstawa;
                let strych = 0;
                let strych_podstawa;
                let komorka = 0;
                let komorka_podstawa;
                let utrzymanie_czystosci = 0;
                let utrzymanie_czystosci_podstawa;
                let wywoz_smieci = 0;
                let wywoz_smieci_podstawa;
                let fundusz_remontowy = 0;
                let fundusz_remontowy_podstawa;
                let konserw_domofonu = 0;
                let konserw_domofonu_podstawa;
                let konserw_windy = 0;
                let konserw_windy_podstawa;
                let opl_za_adm = 0;
                let opl_za_adm_podstawa;
                let abonament_cw = 0;
                let abonament_cw_podstawa;
                let co = 0;
                let co_podstawa;
                let energia_el_lokalu = 0;
                let energia_el_lokalu_podstawa;
                let ryczalt_gaz = 0;
                let ryczalt_gaz_podstawa;
                let zagosp_terenu = 0;
                let zagosp_terenu_podstawa;
                let antena = 0;
                let antena_podstawa;
                let ciepla_woda = 0;
                let ciepla_woda_podstawa;
                let zimna_woda = 0;
                let zimna_woda_podstawa;
                let dod_1 = 0;
                let dod_1_podstawa = 0;
                let dod_1_opis = "";
                let dod_2 = 0;
                let dod_2_podstawa = 0;
                let dod_2_opis = "";
                let dod_3 = 0;
                let dod_3_podstawa = 0;
                let dod_3_opis = "";

                tablicaMiesiecy?.forEach( msc => {
                    const czynsze_msc_do_zsumowania_1 = grupy_dni?.filter( grupa_dni => grupa_dni.id_grupa == grupa.id)[0];

                    const czynsze_msc_do_zsumowania_2 = czynsze_msc_do_zsumowania_1?.temp_dni?.filter( c => rrrrmmdd_to_mm_rrrr(c.dzien) == msc);

                    czynsze_msc_do_zsumowania_2?.forEach( c => {
                        eksploatacja += NaNtoZero(c.czynsz_na_dzien.find( x => x.nazwa === "eksploatacja")?.czynsz);
                        eksploatacja_podstawa = NaNtoZero(c.czynsz_na_dzien?.find(x => x.nazwa === "eksploatacja")?.podstawa);
                        garaz += NaNtoZero(c.czynsz_na_dzien.find( x => x.nazwa === "garaż")?.czynsz);
                        garaz_podstawa = NaNtoZero(c.czynsz_na_dzien?.find( x => x.nazwa==="garaż")?.podstawa);
                        strych += NaNtoZero(c.czynsz_na_dzien.find( x => x.nazwa === "strych")?.czynsz);
                        strych_podstawa = NaNtoZero(c.czynsz_na_dzien?.find( x => x.nazwa==="strych")?.podstawa);
                        komorka += NaNtoZero(c.czynsz_na_dzien.find( x => x.nazwa === "komorka")?.czynsz);
                        komorka_podstawa = NaNtoZero(c.czynsz_na_dzien?.find( x => x.nazwa==="komorka")?.podstawa);
                        utrzymanie_czystosci += NaNtoZero(c.czynsz_na_dzien.find( x => x.nazwa === "utrzymanie_czystosci")?.czynsz);
                        utrzymanie_czystosci_podstawa = NaNtoZero(c.czynsz_na_dzien?.find( x => x.nazwa==="utrzymanie_czystosci")?.podstawa);
                        wywoz_smieci += NaNtoZero(c.czynsz_na_dzien.find( x => x.nazwa === "wywoz_smieci")?.czynsz);
                        wywoz_smieci_podstawa = NaNtoZero(c.czynsz_na_dzien?.find( x => x.nazwa==="wywoz_smieci")?.podstawa);
                        fundusz_remontowy += NaNtoZero(c.czynsz_na_dzien.find( x => x.nazwa === "fundusz_remontowy")?.czynsz);
                        fundusz_remontowy_podstawa = NaNtoZero(c.czynsz_na_dzien?.find( x => x.nazwa=== "fundusz_remontowy")?.podstawa);
                        konserw_domofonu += NaNtoZero(c.czynsz_na_dzien.find( x => x.nazwa === "konserw_domofonu")?.czynsz);
                        konserw_domofonu_podstawa = NaNtoZero(c.czynsz_na_dzien?.find( x => x.nazwa=== "konserw_domofonu")?.podstawa);
                        konserw_windy += NaNtoZero(c.czynsz_na_dzien.find( x => x.nazwa === "konserw_windy")?.czynsz);
                        konserw_windy_podstawa = NaNtoZero(c.czynsz_na_dzien?.find( x => x.nazwa=== "konserw_windy")?.podstawa);
                        opl_za_adm += NaNtoZero(c.czynsz_na_dzien.find( x => x.nazwa === "opl_za_adm")?.czynsz);
                        opl_za_adm_podstawa = NaNtoZero(c.czynsz_na_dzien?.find( x => x.nazwa=== "opl_za_adm")?.podstawa);
                        abonament_cw += NaNtoZero(c.czynsz_na_dzien.find( x => x.nazwa === "abonament_cw")?.czynsz);
                        abonament_cw_podstawa = NaNtoZero(c.czynsz_na_dzien?.find( x => x.nazwa==="abonament_cw")?.podstawa);
                        co += NaNtoZero(c.czynsz_na_dzien.find( x => x.nazwa === "co")?.czynsz);
                        co_podstawa = NaNtoZero(c.czynsz_na_dzien?.find( x => x.nazwa==="co")?.podstawa);
                        energia_el_lokalu += NaNtoZero(c.czynsz_na_dzien.find( x => x.nazwa === "energia_el_lokalu")?.czynsz);
                        energia_el_lokalu_podstawa = NaNtoZero(c.czynsz_na_dzien?.find( x => x.nazwa==="energia_el_lokalu")?.podstawa);
                        ryczalt_gaz += NaNtoZero(c.czynsz_na_dzien.find( x => x.nazwa === "ryczalt_gaz")?.czynsz);
                        ryczalt_gaz_podstawa = NaNtoZero(c.czynsz_na_dzien?.find( x => x.nazwa==="ryczalt_gaz")?.podstawa);
                        zagosp_terenu += NaNtoZero(c.czynsz_na_dzien.find( x => x.nazwa === "zagosp_terenu")?.czynsz);
                        zagosp_terenu_podstawa = NaNtoZero(c.czynsz_na_dzien?.find( x => x.nazwa==="zagosp_terenu")?.podstawa);
                        antena += NaNtoZero(c.czynsz_na_dzien.find( x => x.nazwa === "antena")?.czynsz);
                        antena_podstawa = NaNtoZero(c.czynsz_na_dzien?.find( x => x.nazwa==="antena")?.podstawa);
                        ciepla_woda += NaNtoZero(c.czynsz_na_dzien.find( x => x.nazwa=== "ciepla_woda")?.czynsz);
                        ciepla_woda_podstawa = NaNtoZero(c.czynsz_na_dzien?.find( x => x.nazwa==="ciepla_woda")?.podstawa);
                        zimna_woda += NaNtoZero(c.czynsz_na_dzien.find( x => x.nazwa === "zimna_woda")?.czynsz);
                        zimna_woda_podstawa = NaNtoZero(c.czynsz_na_dzien?.find( x => x.nazwa==="zimna_woda")?.podstawa);
                        dod_1 += NaNtoZero(c.czynsz_na_dzien.find( x => x.nazwa==="dod_1")?.czynsz);
                        dod_1_podstawa = NaNtoZero(c.czynsz_na_dzien?.find( x => x.nazwa==="dod_1")?.podstawa);
                        dod_1_opis = c.czynsz_na_dzien?.find( x => x.nazwa==="dod_1")?.opis;
                        dod_2 += NaNtoZero(c.czynsz_na_dzien.find( x => x.nazwa==="dod_2")?.czynsz);
                        dod_2_podstawa = NaNtoZero(c.czynsz_na_dzien?.find( x => x.nazwa==="dod_2")?.podstawa);
                        dod_2_opis = c.czynsz_na_dzien?.find( x => x.nazwa==="dod_2")?.opis;
                        dod_3 += NaNtoZero(c.czynsz_na_dzien.find( x => x.nazwa==="dod_3")?.czynsz);
                        dod_3_podstawa = NaNtoZero(c.czynsz_na_dzien?.find( x => x.nazwa==="dod_3")?.podstawa);
                        dod_3_opis = c.czynsz_na_dzien?.find( x => x.nazwa==="dod_3")?.opis;
                    })

                    temp_msc.push( {
                        msc: msc,
                        eksploatacja: parseFloat(zera(eksploatacja).toFixed(2)),
                        eksploatacja_podstawa: eksploatacja_podstawa,
                        garaz: parseFloat(zera(garaz).toFixed(2)),
                        garaz_podstawa: garaz_podstawa,
                        strych: parseFloat(zera(strych).toFixed(2)),
                        strych_podstawa: strych_podstawa,
                        komorka: parseFloat(zera(komorka).toFixed(2)),
                        komorka_podstawa: komorka_podstawa,
                        utrzymanie_czystosci: parseFloat(zera(utrzymanie_czystosci).toFixed(2)),
                        utrzymanie_czystosci_podstawa: utrzymanie_czystosci_podstawa,
                        wywoz_smieci: parseFloat(zera(wywoz_smieci).toFixed(2)),
                        wywoz_smieci_podstawa: wywoz_smieci_podstawa,
                        fundusz_remontowy: parseFloat(zera(fundusz_remontowy).toFixed(2)),
                        fundusz_remontowy_podstawa: fundusz_remontowy_podstawa,
                        konserw_domofonu: parseFloat(zera(konserw_domofonu).toFixed(2)),
                        konserw_domofonu_podstawa: konserw_domofonu_podstawa,
                        konserw_windy: parseFloat(zera(konserw_windy).toFixed(2)),
                        konserw_windy_podstawa: konserw_windy_podstawa,
                        opl_za_adm: parseFloat(zera(opl_za_adm).toFixed(2)),
                        opl_za_adm_podstawa: opl_za_adm_podstawa,
                        abonament_cw: parseFloat(zera(abonament_cw).toFixed(2)),
                        abonament_cw_podstawa: abonament_cw_podstawa,
                        co: parseFloat(zera(co).toFixed(2)),
                        co_podstawa: co_podstawa,
                        energia_el_lokalu: parseFloat(zera(energia_el_lokalu).toFixed(2)),
                        energia_el_lokalu_podstawa: energia_el_lokalu_podstawa,
                        ryczalt_gaz: parseFloat(zera(ryczalt_gaz).toFixed(2)),
                        ryczalt_gaz_podstawa: ryczalt_gaz_podstawa,
                        zagosp_terenu: parseFloat(zera(zagosp_terenu).toFixed(2)),
                        zagosp_terenu_podstawa: zagosp_terenu_podstawa,
                        antena: parseFloat(zera(antena).toFixed(2)),
                        antena_podstawa: antena_podstawa,
                        ciepla_woda: parseFloat(zera(ciepla_woda).toFixed(2)),
                        ciepla_woda_podstawa: ciepla_woda_podstawa,
                        zimna_woda: parseFloat(zera(zimna_woda).toFixed(2)),
                        zimna_woda_podstawa: zimna_woda_podstawa,
                        dod_1: parseFloat(zera(dod_1).toFixed(2)),
                        dod_1_podstawa: dod_1_podstawa,
                        dod_1_opis: dod_1_opis,
                        dod_2: parseFloat(zera(dod_2).toFixed(2)),
                        dod_2_podstawa: dod_2_podstawa,
                        dod_2_opis: dod_2_opis,
                        dod_3: parseFloat(zera(dod_3).toFixed(2)),
                        dod_3_podstawa: dod_3_podstawa,
                        dod_3_opis: dod_3_opis,
                    })


                    const stawkiJednorazoweInd_msc = stawkiJednorazoweInd?.find( s => s?.id_user == mieszkaniec?.id_user && s?.grupa_numer == grupa?.grupa_numer && rrrr_mm_dd_to_mm_rrrr(s?.msc) == msc )

                    if (stawkiJednorazoweInd_msc) {
                        temp_msc.forEach( obj => {
                            if (obj.msc === rrrr_mm_dd_to_mm_rrrr(stawkiJednorazoweInd_msc?.msc)) {
                                // console.log(stawkiJednorazoweInd_msc)
                                obj.jednorazowaInd_kwota = stawkiJednorazoweInd_msc?.kwota;
                                obj.jednorazowaInd_opis = stawkiJednorazoweInd_msc?.opis;
                                // console.log(obj)
                            }
                                
                        })
                    }
                    

                    let sumaTemp = 0;
                    const obj = temp_msc[temp_msc.length-1];

                    for (let key in obj) {
                        // Sprawdzenie, czy klucz nie zawiera "_opis" ani "_podstawa"
                        if (!key.includes("_opis") && !key.includes("_podstawa")) {
                          const value = obj[key];
                          // Dodajemy tylko wartości liczbowe
                          if (typeof value === 'number' && (value > 0 || value < 0)) {
                            sumaTemp += value;
                          }
                        }
                    } 
                    
                    temp_msc[temp_msc.length-1].czynsz_na_msc = parseFloat(zera(sumaTemp).toFixed(2));
                    
                    if (grupa?.grupa_numer == 1 && grupa?.id_user == 69) {
                        temp_msc.forEach(obj => {
                            if (obj.msc === '08-2024' || obj.msc === '09-2024') {
                              obj.czynsz_na_msc = 394.38; // Zmniejszamy wartość czynsz_na_msc o 0.01
                            }
                        });
                    }

                    if (grupa?.grupa_numer == 2 && grupa?.id_user == 69) {
                        temp_msc.forEach(obj => {
                            if (obj.msc === '08-2024' || obj.msc === '09-2024' ) {
                              obj.czynsz_na_msc = 418.92; // Zmniejszamy wartość czynsz_na_msc o 0.01
                            }
                          });
                    }

                    if (grupa?.grupa_numer == 8 && grupa?.id_user == 69) {
                        temp_msc.forEach(obj => {
                            if (obj.msc === '08-2024' || obj.msc === '09-2024' ) {
                              obj.czynsz_na_msc = 443.63; // Zmniejszamy wartość czynsz_na_msc o 0.01
                            }
                        });
                    }

                    if (grupa?.grupa_numer == 11 && grupa?.id_user == 69) {
                        temp_msc.forEach(obj => {
                            if (obj.msc === '08-2024' ) {
                              obj.czynsz_na_msc = 409.87; // Zmniejszamy wartość czynsz_na_msc o 0.01
                            }
                        });
                    }

                    if (grupa?.grupa_numer == 11 && grupa?.id_user == 69) {
                        temp_msc.forEach(obj => {
                            if (obj.msc === '09-2024' ) {
                              obj.czynsz_na_msc = 577.35; // Zmniejszamy wartość czynsz_na_msc o 0.01
                            }
                        });
                    }

                    if (grupa?.grupa_numer == 13 && grupa?.id_user == 69) {
                        temp_msc.forEach(obj => {
                            if (obj.msc === '08-2024' || obj.msc === '09-2024') {
                              obj.czynsz_na_msc = 443.63; // Zmniejszamy wartość czynsz_na_msc o 0.01
                            }
                        });
                    }

                    if (grupa?.grupa_numer == 20 && grupa?.id_user == 69) {
                        temp_msc.forEach(obj => {
                            if (obj.msc === '08-2024' || obj.msc === '09-2024') {
                              obj.czynsz_na_msc = 453.02; // Zmniejszamy wartość czynsz_na_msc o 0.01
                            }
                        });
                    }

                    if (grupa?.grupa_numer == 1 && grupa?.id_user == 72) {
                        temp_msc.forEach(obj => {
                            if (obj.msc === '08-2024' || obj.msc === '09-2024') {
                              obj.czynsz_na_msc = 409.00; // Zmniejszamy wartość czynsz_na_msc o 0.01
                            }
                        });
                    }

                    if (grupa?.grupa_numer == 1 && grupa?.id_user == 73) {
                        temp_msc.forEach(obj => {
                            if (obj.msc === '08-2024') {
                              obj.czynsz_na_msc = 567.69; // Zmniejszamy wartość czynsz_na_msc o 0.01
                            }
                        });
                    }

                    if (grupa?.grupa_numer == 1 && grupa?.id_user == 79) {
                        temp_msc.forEach(obj => {
                            if (obj.msc === '09-2024') {
                              obj.czynsz_na_msc = 411.18; // Zmniejszamy wartość czynsz_na_msc o 0.01
                            }
                        });
                    }

                    if (grupa?.grupa_numer == 2 && grupa?.id_user == 79) {
                        temp_msc.forEach(obj => {
                            if (obj.msc === '09-2024') {
                              obj.czynsz_na_msc = 443.37; // Zmniejszamy wartość czynsz_na_msc o 0.01
                            }
                        });
                    }


                    if (grupa?.grupa_numer == 2 && grupa?.id_user == 92) {
                        temp_msc.forEach(obj => {
                            if (obj.msc === '08-2024' || obj.msc === '09-2024') {
                              obj.czynsz_na_msc = 483.36; // Zmniejszamy wartość czynsz_na_msc o 0.01
                            }
                        });
                    }

                    if (grupa?.grupa_numer == 1 && grupa?.id_user == 96) {
                        temp_msc.forEach(obj => {
                            if (obj.msc === '09-2024') {
                              obj.czynsz_na_msc = 463.64; // Zmniejszamy wartość czynsz_na_msc o 0.01
                            }
                        });
                    }

                    if (grupa?.grupa_numer == 1 && grupa?.id_user == 105) {
                        temp_msc.forEach(obj => {
                            if (obj.msc === '08-2024') {
                              obj.czynsz_na_msc = 499.30; // Zmniejszamy wartość czynsz_na_msc o 0.01
                            }
                        });
                    }
                        
                    if (grupa?.grupa_numer == 1 && grupa?.id_user == 109) {
                        temp_msc.forEach(obj => {
                            if (obj.msc === '09-2024') {
                              obj.czynsz_na_msc = 523.49; // Zmniejszamy wartość czynsz_na_msc o 0.01
                            }
                        });
                    }

                    sumaTemp = 0;
                    eksploatacja = 0;
                    garaz = 0;
                    strych = 0;
                    komorka = 0;
                    utrzymanie_czystosci = 0;
                    wywoz_smieci = 0;
                    fundusz_remontowy = 0;
                    konserw_domofonu = 0;
                    konserw_windy = 0;
                    opl_za_adm = 0;
                    abonament_cw = 0;
                    co = 0;
                    energia_el_lokalu = 0;
                    ryczalt_gaz = 0;
                    zagosp_terenu = 0;
                    antena = 0;
                    ciepla_woda = 0;
                    zimna_woda = 0;
                    dod_1 = 0;
                    dod_2 = 0;
                    dod_3 = 0;
                })

                return {
                    id_grupa: grupa.id,
                    grupa_numer: grupa.grupa_numer,
                    temp_msc: temp_msc,
                    id_user: grupa.id_user
                }

            })

            function aggregateData(data) {
                const map = new Map();
              
                data.forEach(item => {
                  const key = `${item.id_user}_${item.grupa_numer}`;
                  
                  if (!map.has(key)) {
                    map.set(key, {
                      grupa_numer: item.grupa_numer,
                      id_user: item.id_user,
                      temp_msc: {}
                    });
                  }
                  
                  // Agregacja danych na miesiąc
                  item.temp_msc.forEach(monthData => {
                    const msc = monthData.msc;
                    if (!map.get(key).temp_msc[msc]) {
                      map.get(key).temp_msc[msc] = {};
                    }
                    
                    Object.entries(monthData).forEach(([keyValue, value]) => {
                      if (keyValue !== 'msc') {
                        if (!map.get(key).temp_msc[msc][keyValue]) {
                          map.get(key).temp_msc[msc][keyValue] = 0;
                        }
                        map.get(key).temp_msc[msc][keyValue] += value;
                      }
                    });
                  });
                });
              
                return map;
            }

            function convertToFinalArray(aggregatedData) {
                // Konwersja mapy do tablicy
                return Array.from(aggregatedData.values()).map(item => ({
                  grupa_numer: item.grupa_numer,
                  id_user: item.id_user,
                  temp_msc: Object.entries(item.temp_msc).map(([msc, values]) => ({
                    msc,
                    ...values
                  }))
                }));
            }

            const aggregatedData = aggregateData(grupy_msc);
            const grupy_udzial_msc = convertToFinalArray(aggregatedData);

            return {
                id_user: mieszkaniec.id_user,
                grupy: grupy_m,
                grupy_dni: grupy_dni,
                grupy_msc: grupy_msc,
                grupy_udzial_msc: grupy_udzial_msc
            }
            

        })

        return {
            id_wspolnoty: wspolnota.id,
            grupy: mieszkancy_temp,
            grupyNoty: grupyNoty
        }

    })
    return wynik;
}

// getTablicaPrzelewy2(wspolnoty, grupy, udzialyKonta, przelewy, mieszkancy)[0]
export const getTablicaPrzelewy2 = (wspolnoty, udzialyKonta, przelewy) => {

    const wynik = wspolnoty?.map( wspolnota => {

        const grupyUdzial_w = udzialyKonta?.filter( grupa => grupa.id_wspolnoty == wspolnota.id);

        const grupy = grupyUdzial_w?.map( grupa => {
            // const przelewy_g = przelewy?.filter( przelew => przelew.odbiorca_konto == grupa?.konto_bankowe || przelew.nadawca_konto == grupa?.konto_bankowe);
            const przelewy_g = przelewy?.filter( przelew => przelew?.id_user == grupa?.id_user && przelew.grupa_numer == grupa?.grupa_numer )
            const przelewy_msc = przelewy_g?.map( p => {
                return {
                    ...p,
                    msc: dataFormatMsc(p.data_operacji)
                }
            })
            
            return {
                nr_grupyUdzial: grupa.grupa_numer,
                id_user: grupa.id_user,
                przelewy: przelewy_msc
            }
        })
        return {
            id_wspolnoty: wspolnota.id,
            grupy: grupy
        }
    })
    return [wynik];
}

export const getSaldo_u = (przelewyWspolnota, czynszeWspolnota, udzialy) => {

    return udzialy?.map( udzial => {
        let suma_przelewy = 0;
        const przelewy = przelewyWspolnota?.find( p => p.nr_grupyUdzial == udzial?.grupa_numer && p.id_user == udzial?.id_user);
        if (przelewy && przelewy?.przelewy?.length > 0) {
            przelewy?.przelewy.forEach( p =>  {
                suma_przelewy += p?.kwota
            })
        }
        const czynsze_u_temp = czynszeWspolnota?.find( c => c.id_user == udzial.id_user);
        let suma_czynsze = 0;
        if (czynsze_u_temp ) {
            czynsze_u_temp?.grupy_udzial_msc?.forEach( c => {
               if (c?.grupa_numer == udzial?.grupa_numer) {
                    c.temp_msc?.slice(0, -1).forEach( cc => {
                        suma_czynsze += cc.czynsz_na_msc;
                   })
               }
            })
        }
        return ({id: udzial.id, saldo: suma_przelewy - suma_czynsze })
    })
}

export const getSaldo_g = (przelewyWspolnota, czynszeWspolnota, udzial) => {

    let suma_przelewy = 0;
    const przelewy = przelewyWspolnota?.find( p => p.nr_grupyUdzial == udzial?.grupa_numer && p.id_user == udzial?.id_user);
    if (przelewy && przelewy?.przelewy?.length > 0) {
        przelewy?.przelewy.forEach( p =>  {
            suma_przelewy += p?.kwota
        })
    }

    const czynsze_u_temp = czynszeWspolnota?.find( c => c.id_user == udzial.id_user);

    let suma_czynsze = 0;
    if (czynsze_u_temp ) {
        czynsze_u_temp?.grupy_udzial_msc?.forEach( c => {

           if (c?.grupa_numer == udzial?.grupa_numer) {
                c.temp_msc?.slice(0, -1).forEach( cc => {
                    suma_czynsze += cc.czynsz_na_msc;
               })
           }
        })
    }
    return suma_przelewy - suma_czynsze;
}

export const getSaldo_w = (tablicaPrzelewy2, tablicaCzynsze2, wspolnoty) => {

    const tablica_przelewy = tablicaPrzelewy2?.map( tablica => {
        let suma_przelewy = 0;
        tablica?.grupy?.forEach( t => {
            t.przelewy.forEach( p => {
                suma_przelewy += p.kwota;
            })
        })
        return {
            id_wspolnoty: tablica.id_wspolnoty,
            suma: suma_przelewy
        }
    });
    
    const tablica_czynsze = tablicaCzynsze2?.map( tablica => {
        let suma_czynsze = 0;
        tablica?.grupy?.forEach( g => {
            g?.grupy_msc?.forEach( gm => {
                gm?.temp_msc?.forEach( tm => {
                    suma_czynsze += tm?.czynsz_na_msc
                })
            })
        })

        return {
            id_wspolnoty: tablica.id_wspolnoty,
            suma: suma_czynsze
        }
    })

    const saldo = wspolnoty.map( wspolnota => {
        
        const przelewy = tablica_przelewy?.filter( p => p.id_wspolnoty == wspolnota.id )[0]?.suma;
        const czynsze = tablica_czynsze?.filter( c => c.id_wspolnoty == wspolnota.id )[0]?.suma;
        return {
            id_wspolnoty: wspolnota.id,
            saldo: przelewy - czynsze
        }
    })

    return saldo;
}



export const getSaldo = (tablicaPrzelewy, tablicaCzynsze) => {
    if (tablicaPrzelewy && tablicaPrzelewy.length > 0 && tablicaCzynsze && tablicaCzynsze.length > 0) {

        const sumaPrzelewow = sumujPrzelewy(tablicaPrzelewy);

        // const sumaCzynszow = sumujCzynsze(tablicaCzynsze);
        
        const roznica = [] //obliczRoznice(sumaPrzelewow, sumaCzynszow)
    
        return roznica;
    }
    else 
        return [];
    

} 

function sumujPrzelewy(tablicaPrzelewy) {
    const sumaMap = [];
    tablicaPrzelewy.forEach(el => {
        const grupyMap = {};
        el.grupy.forEach(grupa => {
            const key = `${grupa.id_user}_${grupa.grupa_numer}`;
            if (!grupyMap[key]) {
                grupyMap[key] = {
                    id_user: grupa.id_user,
                    grupa_numer: grupa.grupa_numer,
                    suma: 0
                };
            }
            grupyMap[key].suma += grupa.suma;
        });
        const grupy = Object.values(grupyMap);
        sumaMap.push({ id_wspolnoty: el.id_wspolnoty, grupy });
    });
    return sumaMap;
}

// export const saldoWykrzyknik = () => <span style={{"fontSize": "18px","fontWeight": "800","color": "red" }}>{"  ! "}</span>;
export const saldoWykrzyknik = () => <span></span>;

export const usunKluczNaN = array => {
    return array.map(obj => {
        let newObj = {};
    
        for (let key in obj) {
          if (!isNaN(obj[key]) || key === "msc") {
            newObj[key] = obj[key];
          }
        }
    
        return newObj;
      });
}

export const hasKey = (obj, key) => {
    if (obj)
        return key in obj && obj[key] > 0 ? true : false       
};

export const arrayHasElement = (array, element) => {
    return array.includes(element);
}

export const arrayHasKey = (array, key) => {
    let temp = false;
    if (array && array?.length) {
        array?.forEach( a => {
            temp = key in a ? true : false
        })
        return temp;
    } else 
        return false; 
}

export const filterKeysWithNumbers = data => {
    const validKeys = new Set();
    data.forEach(obj => {
        for (const key in obj) {
            if (!isNaN(obj[key])) {
                validKeys.add(key);
            }
        }
    });
    return data.map(obj => {
        let newObj = {};
        validKeys.forEach(key => {
            if (Object.prototype.hasOwnProperty.call(obj, key) && (obj[key] > 0 || obj[key] < 0 || obj[key] == 0) ) {
                newObj[key] = obj[key];
            }
        });
        return newObj;
    });
}

import React from "react";

import { dataFormat } from '../../../functions/dataFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'

const StanLicznikowAddFormListaOdczytow = ( props ) => {

  const { liczniki, loadLicznik } = props;

  console.log(liczniki)

  const listaOdczytow = liczniki?.map( (el, i) => (
    <li key={i}>
      <div className="poczatek">
        <div className="naglowek">
          <div className="opis"></div>
          <div className="value">Początek</div>
          <div className="value">Koniec</div>
        </div>
        <div className="data">
          <div className="opis">Okres: </div>
          <div className="value">{dataFormat(el.okres_poczatek)}  </div> 
          <div className="value">{el.okres_koniec == null ? "brak" : dataFormat(el.okres_koniec)}</div>
          <FontAwesomeIcon 
            icon={faPen} 
            className="pointer" 
            onClick={()=>{loadLicznik(el.id, el.okres_poczatek, el.okres_koniec, el.ciepla_stan_poczatek, el.ciepla_stan_koniec, el.ciepla_zuzycie, el.ciepla_koszt, el.zimna_stan_poczatek, el.zimna_stan_koniec, el.zimna_zuzycie, el.zimna_koszt)}} />
        </div>
        <div className="stan">
          <div className="opis">Stawka wody ciepłej:</div>
          <div className="value">{ el.ciepla_cena + " zł/m3"}</div>
        </div>
        <div className="stan">
          <div className="opis">Stawka wody zimnej:</div>
          <div className="value">{ el.zimna_cena + " zł/m3"}</div>
        </div>
        <div className="stan ciepla">
          <div className="opis">Woda ciepła: </div>
          <div className="value">{el.ciepla_stan_poczatek + " m3"}</div>
          <div className="value">{el.ciepla_stan_koniec == null || el.ciepla_stan_koniec == "NaN-NaN-NaN" ? "brak" : el.ciepla_stan_koniec + " m3"}</div>
        </div>
        <div className="stan">
          <div className="opis">Woda zimna: </div>
          <div className="value">{el.zimna_stan_poczatek + " m3"}</div>
          <div className="value">{el.zimna_stan_koniec == null || el.zimna_stan_koniec == "NaN-NaN-NaN" ? "brak" : el.zimna_stan_koniec + " m3"}</div>
        </div>
      </div>
      
      <div className="zuzycie ciepla">
        <div className="opis">Zużycie/koszt wody ciepłej:</div>
        <div className="value">{el.ciepla_zuzycie == null || el.ciepla_zuzycie <= 0 ? "brak" : el.ciepla_zuzycie + " m3"}</div>
        <div className="value">{el.ciepla_koszt == null ? "brak" : el.ciepla_koszt + " zł"}</div>
      </div>  
      <div className="zuzycie">
        <div className="opis">Zużycie/koszt wody zimnej:</div>
        <div className="value">{el.zimna_zuzycie == null || el.zimna_zuzycie <= 0 ? "brak" : el.zimna_zuzycie + " m3"}</div>
        <div className="value">{el.zimna_koszt == null ? "brak" : el.zimna_koszt + " zł"}</div>
      </div>
    </li>
  ))

  return(
    <div className="listaLicznikow">
      <ul className="">
        {
          liczniki && liczniki.length > 0 ? listaOdczytow : (<b>brak wprowadzonych odczytów</b>) 
        }
      </ul>
    </div>
  )
}

export default StanLicznikowAddFormListaOdczytow;
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { dataFormat } from '../../../functions/dataFormat';
import { zl } from "../../../functions/utilities";
import '../../../scss/style.css';
import '../../../scss/form.css';
import '../../../scss/listy.css';
import '../../../scss/ksiegowosc.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import FiltrWspolnoty from '../FiltrWspolnoty.js';
import FiltrMieszkancy from '../FiltrMieszkancy.js';
import HistoriaTransakcjiListaPlikow from './HistoriaTransakcjiListaPlikow.js';
import { fetchPrzelewy } from '../../../Redux/Actions.js'; 
import DialogYesNo from "../../DialogYesNo.js";

const HistoriaTransakcji = (props) => {

    const {wspolnoty, udzialyKontaAkt, mieszkancy, przelewy} = props;
    const dispatch = useDispatch();

    const [wybranaWspolnotaId, setWybranaWpolnotaId] = useState(0);
    const [wybranyMieszkaniecId, setWybranyMieszkaniecId] = useState(0);
    const [listaF, setListaF] = useState([]);
    const [open, setOpen] = useState(false);
    const [trashId, setTrashId] = useState(0);
    const [mieszkancy_w, setMieszkancy_w] = useState([]);
    const [przelewy_a, setPrzelewy_a] = useState([]);

    useEffect( () => {
        setMieszkancy_w( mieszkancy?.filter( m => m.id_wspolnoty == wybranaWspolnotaId) )
    },[mieszkancy, wybranaWspolnotaId]);

    useEffect( ( ) => {
        if (wybranyMieszkaniecId == 0)
            setPrzelewy_a( przelewy?.filter( p => p.id_wspolnoty == wybranaWspolnotaId && p?.operacja !== "prowizja") )
        else if (wybranyMieszkaniecId  > 0)
            setPrzelewy_a( przelewy?.filter( p => p?.id_user == wybranyMieszkaniecId && p?.operacja !== "prowizja") )
    },[wybranaWspolnotaId, wybranyMieszkaniecId, przelewy]);

    useEffect( () => {
        setListaF( przelewy_a?.filter( p => p?.id_wspolnoty == wybranaWspolnotaId && p.operacja !== "prowizja") )
    },[wybranaWspolnotaId, przelewy_a]);

    async function usunPrzelew(id) {
        try {
          const response = await axios.post('/api/przelewy/usun', { id: id });
          if (response.data.success === true)  {
            dispatch(fetchPrzelewy()); 
          }

          console.log('Response from backend:', response.data);
        } catch (error) {
          console.error('Error sending ID to backend:', error);
        }
    }

    useEffect( () => {
        if (wspolnoty && wspolnoty?.length > 0)
            setWybranaWpolnotaId( wspolnoty[0]?.id )
    },[wspolnoty, mieszkancy])

    const handleClick = id => {
        setTrashId(id)
        setOpen(true);  
    }

    return (
        <div className="przelewy">
            <DialogYesNo 
                open={open}
                setOpen={setOpen}
                message={`Czy na pewno chcesz usunąć ten przelew?`}
                f={usunPrzelew}
                id={trashId}
            />
            <h4 className="nazwa">
                Historia przelewów/transakcji
            </h4>

            <div className='historia'>
                <div className='filtry'>
                    <FiltrWspolnoty 
                        wspolnoty={wspolnoty}
                        wybranaWspolnotaId={wybranaWspolnotaId}
                        setWybranaWpolnotaId={setWybranaWpolnotaId}
                        setWybranyMieszkaniecId={setWybranyMieszkaniecId}

                    />
                    <FiltrMieszkancy 
                        mieszkancy_w={mieszkancy_w}
                        wybranyMieszkaniecId={wybranyMieszkaniecId}
                        setWybranyMieszkaniecId={setWybranyMieszkaniecId}
                    />
                </div> 
                <div
                    style={{
                        "maxHeight": "500px",
                        "overflow": "auto"
                    }}
                >
                    <table>
                        <thead>
                            <tr>
                                <th>Nazwa pliku</th>
                                <th>ID</th>
                                <th
                                    style={{
                                        maxWidth: "120px"
                                    }}
                                >Operacja</th>
                                <th 
                                    style={{minWidth: "80px"}}
                                >Data</th>
                                <th>Mieszkaniec / nadawca/ odbiorca</th>
                                <th>Kwota</th>
                                <th
                                    style={{maxWidth: "250px"}}
                                >Tytuł</th>
                                <th
                                    style={{
                                        textAlign: "center"
                                    }}
                                >
                                    Grupa numer
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {listaF?.map((row, index) => {
                                
                                const mieszkaniecTxt = () => {
                                    
                                    if (row.nadawca_konto === "Konto główne wspólnoty" && row.grupa_numer === 0) {
                                        return "Konto główne wspólnoty";
                                    } else if (row.odbiorca_konto === "Konto główne wspólnoty") {
                                        return row.nadawca_nazwa;
                                    } else if ( row.operacja === "przelew wychodzący" ) {
                                        return row.odbiorca_nazwa;
                                    } else {
                                        return mieszkancy?.find( m => m.id_user == row?.id_user)?.imie_nazwisko;
                                    }
                                }

                                return (
                                    <tr key={index} className={ index % 2 ? "" : "g" }>
                                        <td>{ row.operacja === "przeksięgowanie z" || row.operacja === "przeksięgowanie na" ? "" : row.nazwa_pliku}</td>
                                        <td>{ row.operacja === "przeksięgowanie z" || row.operacja === "przeksięgowanie na" ? row.id : row.id_transkacji}</td>
                                        <td>{row.operacja}</td>
                                        <td>{dataFormat(row.data_operacji)}</td>
                                        <td>
                                            {mieszkaniecTxt() == undefined && row.operacja !== "prowizja" ? <span className='red'>Numer konta nieprzypisany do żadnej grupy czynszowej</span> : mieszkaniecTxt()}
                                        </td>
                                        <td
                                            style={{textAlign: "right"}}
                                        >
                                            {zl(row.kwota)}
                                        </td>
                                        <td>{row.nazwa_transakcji}</td>
                                        <td
                                            style={{
                                                textAlign: "center"
                                            }}
                                        >
                                            {row.grupa_numer}
                                        </td>
                                        <td><span className='pointer' onClick={()=>handleClick(row.id)}><FontAwesomeIcon icon={faTrash} /></span></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

            </div>
            <HistoriaTransakcjiListaPlikow
                przelewy={przelewy}
                wspolnoty={wspolnoty}
                wybranaWspolnotaId={wybranaWspolnotaId}
            />
        </div>
    )
}

export default HistoriaTransakcji;
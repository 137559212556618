import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import axios from "axios";
import { motion } from 'framer-motion';
import { wstepy } from '../../../functions/motions.js';
import { format } from 'date-fns';
import { z } from "../../../functions/utilities.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import DialogYesNo from "../../DialogYesNo.js";
import { 
    fetchPlanowaneZuzycie
  } from '../../../Redux/Actions.js'; 

const ZuzycieLista = props => {

    const { planowaneZuzycie_u, setPlanowaneZuzycie_u } = props;
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false)
    // console.log(planowaneZuzycie_u)
    const handleDelete = (id) => {
        const listaNowa = planowaneZuzycie_u?.filter( el =>  el.id !== id );
        setPlanowaneZuzycie_u(listaNowa);
        axios.post("/api/planowane_zuzycie/usun", { id })
            .then(response => {
                dispatch(fetchPlanowaneZuzycie());
            })
        setOpen( false );
    }
    
    const [trashId, setTrashId] = useState(0);

    const handleOpen = (id) => {
        setTrashId(id);
        setOpen(true);
    }

    const lista = planowaneZuzycie_u?.map( (p,i) => {

        return (
            <motion.li
                key={i}
                variants={wstepy}
                initial="initial"
                animate="animate"
            >   
                <div className="dane">
                    <div className="wierszData">
                        <div className="opis">
                            Data od: 
                        </div>
                        <div className="value">
                            {format(p.data_od, 'yyyy-MM-dd')}
                        </div>
                    </div>
                    <div className="wierszZuzycia">
                        <div className="opis">
                            Zimna woda: 
                        </div>
                        <div className="value">
                            {`${z(p.woda_zimna)} m³`}
                        </div>
                        <div className="opis">
                            Ciepła woda: 
                        </div>
                        <div className="value">
                            {`${z(p.woda_ciepla)} m³`}
                        </div>
                    </div>
                </div>
                <div className="kosz">
                    <FontAwesomeIcon icon={faTrash} size="2xs" className="pointer" onClick={()=>handleOpen(p.id)}/>
                </div>
            </motion.li>
        )
    })

    return (
        <div className="lista">
            {lista && lista.length > 0 ? (
                <h4>Zapisane planowane zużycia:</h4>
            ) : (
                <h4>Brak wcześniejszych zapisów</h4>
            ) }
            <DialogYesNo
                open={open}
                setOpen={setOpen}
                message={"Czy na pewno chcesz usunąć ten wpis?"}
                f={handleDelete}
                id={trashId}
            />
            <ul>
                 {lista}
            </ul>
        </div>
    )
}

export default ZuzycieLista;
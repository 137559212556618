import React, {useContext, useState, useEffect} from 'react';
import axios from 'axios';
import '../../scss/style.css';
import '../../scss/komunikaty.css';
import '../../scss/form.css';
import { AuthContext } from '../../Context/AuthProvider';
import { dataFormat2 } from '../../functions/dataFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import scrollToTop from '../../functions/ScrollToTop';
import { motion } from 'framer-motion';
import { once } from '../../functions/motions';

const KomunikatyLista = () =>{
    useEffect( ()=> {
        scrollToTop();
    },[])
    const { auth, role } = useContext(AuthContext);
    const [ komunikaty, setKomunikaty ] = useState([]);
    const [ pliki, setPliki ] = useState([]);

    useEffect(()=>{
        axios.get("/api/komunikaty", { })
        .then(response => {
            const { data } = response;
            setKomunikaty( data )
        })
    },[]);

    useEffect(()=>{
        axios.get("/api/komunikaty/att", { })
        .then(response => {
            const { data } = response;
            setPliki( data )
        })
    },[]);
    
    const deletePost = (id) => {
        const listaNowa = komunikaty?.filter( el =>  el.id !== id );
        setKomunikaty(listaNowa);
        axios.post("/api/komunikaty/usun", { id })
    }

    const deleteAtt = (id) => {
        const plikiNowe = pliki?.filter( el => el.id !== id);
        setPliki(plikiNowe);
        axios.post("/api/komunikaty/att/delete", { id })
    }

    const pobierz = async fileName => {
        // console.log(fileName)
        try {
            const response = await axios.post( 
                '/api/komunikaty/pobierz',
                { fileName: fileName }, 
                { responseType: 'blob' })
            // console.log(response);
            // if (response.statusText !== "OK") {
            //   throw new Error('Błąd podczas pobierania pliku PDF');
            // }
      
            const url = window.URL.createObjectURL(new Blob([response.data]));
      
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // Nazwa pliku do pobrania
            document.body.appendChild(link);
            console.log(link)
            link.click();
            link.parentNode.removeChild(link);
          } catch (error) {
            console.log(error);
            alert(`Plik "${fileName}" nie istnieje.`)
          }
    }   

    const handleDownloadFile = async (filename) => {
        try {
            console.log(filename)
          const response = await axios.get(`/api/download/${filename}`, {
            responseType: 'blob'
          });
          
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        } catch (error) {
          console.error('Error downloading file:', error);
        }
    };

    const lista = komunikaty?.map( (el, i) => {
        
        const pliki_k = pliki?.filter( p => p.id_komunikat == el.id);
        const lista = pliki_k?.map( (p, i) => {
            return (
                <li key={i}>
                    <div className='pointer' onClick={()=>{pobierz(p.nazwa_pliku)}}>
                        <FontAwesomeIcon icon={faFileArrowDown} /> <span className='opis'>{`${p.opis}`}</span>
                    </div>     
                    { auth && role === "admin" && <div className='kosz'><FontAwesomeIcon icon={faTrash} size="2xs" className="pointer" onClick={()=>{deleteAtt(p.id)}}/></div> }
                </li>
            )
        })

        return (
                <motion.div key={i}
                    initial={{opacity: 0, scale: 0}}
                    whileInView={{opacity: 1, scale: 1}}
                    viewport={once}
                >
                    {el.status === null && new Date(el.data) <= new Date()? (
                        <div className='komunikat cienie'>
                            <div className='naglowek' style={{"position": "relative"}}>
                                <div className='tytul'>{el.tytul} 
                                    { auth && role === "admin" && <FontAwesomeIcon icon={faTrash} size="2xs" className="pointer" style={{"position": "absolute", "right": 0, "color": "red"}} onClick={()=>{deletePost(el.id)}}/> }
                                </div>
                                <div className='data'>{dataFormat2(el.data)}</div>
                            </div>
                            <div className='tresc'>
                                <span style={{ whiteSpace: 'pre-line' }}>{el.tresc}</span>
                            </div>
                            {
                                pliki_k?.length > 0 ? (
                                    <div className='plikiLista'>
                                        <p>Załączniki:</p>
                                        <ul>
                                            {lista}
                                        </ul>
                                        
                                    </div>
                                ) : ""
                            }
                        </div>
                    ) : ""}
                </motion.div>
        )
    })

    return (
        <div className='komunikaty'>
            {lista}
        </div>
    )
}

export default KomunikatyLista;
import React, { useState, useEffect } from "react";

import SelectWspolnoty from "../SelectWspolnoty.js"
import ListaMieszkancow from "../ListaMieszkancow.js";
import StanLicznikowAddFormListaOdczytow from "./StanLicznikowListaOdczytow.js";
import StanLicznikowFormularz from './StanLicznikowFormularz.js';

const StanLicznikow = (props) => {
  
  const { stawki, mieszkancy, grupyAkt, liczniki, lokale, ulice, udzialyKonta, wspolnoty, activeWindow } = props;

  const [wybranaWspolnota, setWybranaWspolnota] = useState(undefined);
  const [wybranyUdzialKonto, setWybranyUdzialKonto] = useState([]);
  const [wybranyMieszkaniec, setWybranyMieszkaniec] = useState(0);
  const [cieplaWodaCena, setCieplaWodaCena] = useState(0);
  const [zimnaWodaCena, setZimnaWodaCena] = useState(0);
  const [abonamentCwCena, setAbonamentCwCena] = useState(0);
  const [cieplaWoda, setCieplaWoda] = useState(0);
  const [zimnaWoda, setZimnaWoda] = useState(0);
  
  const [dataOd, setDataOd] = useState("");

  const [stawki_w, setStawki_w] = useState([]);
  const [rozliczanieWody, setRozliczanieWody] = useState("");
  const [rozliczanieWodyTxt, setRozliczanieWodyTxt] = useState("-");
  
  useEffect( () => setWybranyMieszkaniec( mieszkancy?.find( m => m.id_user == wybranyUdzialKonto?.id_user) ), [mieszkancy, wybranyUdzialKonto]);
  useEffect( () => setStawki_w( stawki?.filter( s => s.id_wspolnoty == wybranaWspolnota?.id ) ), [stawki, wybranaWspolnota]);
  useEffect( () => setWybranaWspolnota( wspolnoty?.length > 0 ? wspolnoty[0] : []), [wspolnoty]);




  useEffect( () => {
    const temp = stawki_w[0]?.rozliczanie_wody;

    if (temp) {
      setRozliczanieWody( temp );
      setRozliczanieWodyTxt( temp == 1 ? "prosty" : "fakturowy" ); 
    } else {
      setRozliczanieWody( 0 );
      setRozliczanieWodyTxt( "-" ); 
    }
  },[stawki_w]);

  useEffect( () => {
    if (wybranaWspolnota === undefined) {
      setCieplaWodaCena( 0 );
      setZimnaWodaCena( 0 );
    } else if (wybranaWspolnota && stawki_w && stawki_w.length > 0) {
      if (wybranaWspolnota?.id == stawki_w[0].id_wspolnoty) {
        setCieplaWodaCena( stawki_w[0].ciepla_woda );
        setZimnaWodaCena( stawki_w[0].zimna_woda );  
        setAbonamentCwCena( stawki_w[0].abonament_cw );
      } else {
        setCieplaWodaCena( 0 );
        setZimnaWodaCena( 0 );
        setAbonamentCwCena( 0 );
      }
    }
  },[wybranaWspolnota, stawki_w])

  const [loadedLicznik, setLoadedLicznik] = useState([]);

  const [odczyty_u, setOdczyty_u] = useState([]);

  const [mode, setMode] = useState("new");

  const loadLicznik = (id, okres_poczatek, okres_koniec, ciepla_stan_poczatek, ciepla_stan_koniec, ciepla_zuzycie, ciepla_koszt, zimna_stan_poczatek, zimna_stan_koniec, zimna_zuzycie, zimna_koszt, activeWindow) => {
    const obj = {
      id,
      okres_poczatek,
      okres_koniec,
      ciepla_stan_poczatek, 
      ciepla_stan_koniec, 
      ciepla_zuzycie, 
      ciepla_koszt,
      zimna_stan_poczatek, 
      zimna_stan_koniec, 
      zimna_zuzycie,
      zimna_koszt
    }
    
    const poczatek = new Date(obj.okres_poczatek);
    let koniec;
    if ( obj.okres_koniec != null ) {
      koniec = new Date(obj.okres_koniec);
      const formatowanaDataKoniec = `${koniec.getFullYear()}-${String(koniec.getMonth() + 1).padStart(2, "0")}-${String(koniec.getDate()).padStart(2, "0")}`;
      obj.okres_koniec = formatowanaDataKoniec;
    }
    else obj.okres_koniec = "";

    const formatowanaDataPoczatek = `${poczatek.getFullYear()}-${String(poczatek.getMonth() + 1).padStart(2, "0")}-${String(poczatek.getDate()).padStart(2, "0")}`;
    
    obj.okres_poczatek = formatowanaDataPoczatek;
    
    setMode("edit");
    setLoadedLicznik(obj);
  }

  return (
    <div className="content woda">
      <h3 className="nazwa">Wprowadź stan liczników</h3>
      <div>
        <div className="naglowek wiersz">
          <SelectWspolnoty 
            wybranaWspolnota={wybranaWspolnota}
            setWybranaWspolnota={setWybranaWspolnota}
            wspolnoty={wspolnoty}
            setWybranyMieszkaniec={setWybranyMieszkaniec}
            setWybranyUdzialKonto={setWybranyUdzialKonto}
          />
          <div className="cena">


          </div>
        </div>
         
        <div className="container">
          <ListaMieszkancow 
            wybranyMieszkaniec={wybranyMieszkaniec}
            setWybranyMieszkaniec={setWybranyMieszkaniec}
            wybranyUdzialKonto={wybranyUdzialKonto}
            setWybranyUdzialKonto={setWybranyUdzialKonto}
            setMode={setMode}
            setLoadedLicznik={setLoadedLicznik}
            wybranaWspolnota={wybranaWspolnota}
            udzialyKonta={udzialyKonta}
            grupyAkt={grupyAkt}
            mieszkancy={mieszkancy}
            lokale={lokale}
            ulice={ulice}
            activeWindow={activeWindow}
            setZimnaWoda={setZimnaWoda}
            setCieplaWoda={setCieplaWoda}
            setDataOd={setDataOd}
          />
          <div className="rightSide">
            
            {wybranyMieszkaniec?.id > 0 ? (
                <StanLicznikowFormularz 
                  mode={mode}
                  setMode={setMode}
                  loadedLicznik={loadedLicznik}
                  wybranaWspolnota={wybranaWspolnota}
                  wybranyMieszkaniec={wybranyMieszkaniec}
                  setWybranyMieszkaniec={setWybranyMieszkaniec}
                  setWybranyUdzialKonto={setWybranyUdzialKonto}
                  mieszkancy={mieszkancy}
                  stawki={stawki}
                  cieplaWodaCena={cieplaWodaCena}
                  zimnaWodaCena={zimnaWodaCena}
                  abonamentCwCena={abonamentCwCena}
                  rozliczanieWody={rozliczanieWody}
                  rozliczanieWodyTxt={rozliczanieWodyTxt}
                />
              ) : ""}
            <StanLicznikowAddFormListaOdczytow 
              liczniki={liczniki.filter(licznik=>licznik.id_user==wybranyMieszkaniec?.id_user)}
              loadLicznik={loadLicznik}
            /> 
          </div>

        </div>

      </div>
    </div>
  )
}

export default StanLicznikow;
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import GoBackBtn from '../GoBackBtn';
import StanLicznikow from './StanLicznikow/StanLicznikow';
import Zuzycie from './Zuzycie/Zuzycie.js';
import Rozliczenie from './Rozliczenie/Rozliczenie.js';
import { motion } from 'framer-motion';
import { wstepy } from '../../functions/motions';
import { getTablicaCzynsze2 } from '../../functions/tablice.js';

import { 
    fetchWspolnoty,
    fetchMieszkancy,
    fetchStawki,
    fetchOdczyty,
    fetchLokale,
    fetchUlice,
    fetchGrupy,
    fetchGrupyAkt,
    fetchUdzialyKonta,
    fetchWersje,
    fetchPlanowaneZuzycie,
    fetchStawkiJednorazowe,
    fetchStawkiJednorazoweInd,
    fetchStawkiInd
  } from '../../Redux/Actions.js'; 

const Woda = () => {

    const wspolnoty = useSelector( state  => state?.wspolnoty?.data);
    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const stawki = useSelector( state => state?.stawki?.data);
    const liczniki = useSelector( state => state?.odczyty?.data);
    const lokale = useSelector( state => state?.lokale?.data);
    const ulice = useSelector( state => state?.ulice?.data);
    const grupy = useSelector( state => state?.grupy?.data);
    const grupyAkt = useSelector( state => state?.grupyAkt?.data)
    const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);
    const planowaneZuzycie = useSelector( state => state?.planowaneZuzycie?.data);
    const stawkiJednorazowe = useSelector( state => state?.stawkiJednorazowe?.data);
    const stawkiJednorazoweInd = useSelector( state => state?.stawkiJednorazoweInd?.data);
    const stawkiInd = useSelector( state => state?.stawkiInd?.data );
    // const wersje = useSelector( state => state?.wersje?.data);

    const dispatch = useDispatch();

    useEffect( ()=> {
        dispatch(fetchWspolnoty()); 
        dispatch(fetchMieszkancy()); 
        dispatch(fetchStawki()); 
        dispatch(fetchOdczyty());
        dispatch(fetchLokale());
        dispatch(fetchUlice());
        dispatch(fetchGrupy()); 
        dispatch(fetchUdzialyKonta());
      },[dispatch]);

    //   useEffect( () => {
    //     dispatch(fetchWersje(grupy));
    //   },[dispatch, grupy]); 

    //   useEffect( () => {
    //     dispatch(fetchGrupyAkt(grupy, wersje));
    //   },[dispatch,grupy,wersje])

    const [activeWindow, setActiveWindow] = useState("")
    const [tablicaCzynsze, setTablicaCzynsze] = useState([]);

    useEffect( () => {
        setTablicaCzynsze(getTablicaCzynsze2(wspolnoty, mieszkancy, grupy, [],udzialyKonta, udzialyKontaAkt, lokale, stawki, planowaneZuzycie, stawkiJednorazowe, stawkiInd, stawkiJednorazoweInd /*, zaliczki */))
    },[wspolnoty, mieszkancy, grupy, udzialyKonta, lokale, stawki, planowaneZuzycie, stawkiJednorazowe, stawkiInd, stawkiJednorazoweInd /*, zaliczki */]);
    
    const window = () => {
        switch(activeWindow) {
            case "dodajStanLicznikow":
                return <StanLicznikow
                    stawki={stawki}
                    mieszkancy={mieszkancy}
                    grupyAkt={grupyAkt}
                    liczniki={liczniki}
                    lokale={lokale}
                    ulice={ulice}
                    udzialyKonta={udzialyKontaAkt}
                    wspolnoty={wspolnoty}
                    activeWindow={activeWindow}
                />;
            case "dodajZuzycie":
                return <Zuzycie
                    mieszkancy={mieszkancy}
                    grupyAkt={grupyAkt}
                    udzialyKonta={udzialyKontaAkt}
                    lokale={lokale}
                    ulice={ulice}
                    wspolnoty={wspolnoty}
                    activeWindow={activeWindow}
                />;
            case "rozliczenie":
                return <Rozliczenie
                    mieszkancy={mieszkancy}
                    grupyAkt={grupyAkt}
                    udzialyKonta={udzialyKontaAkt}
                    lokale={lokale}
                    ulice={ulice}
                    wspolnoty={wspolnoty}
                    activeWindow={activeWindow}
                    liczniki={liczniki}
                    tablicaCzynsze={tablicaCzynsze}
                />
            default: return <></> 
        }
    }

    return (
        <motion.div 
            className="content woda"
            variants={wstepy}
            initial="initial"
            animate="animate"
        >
            <h1 className="nazwa">Woda - stan liczników i zużycie</h1>
            {/* <p><span className="red">w budowie <FontAwesomeIcon icon={faPersonDigging} /></span></p> */}
            <GoBackBtn />
            <button 
                onClick={()=>setActiveWindow("dodajZuzycie")}
            >
                Wprowadź planowane zużycie
            </button>
            <button 
                onClick={()=>setActiveWindow("dodajStanLicznikow")}
            >
                Wprowadź stan liczników
            </button>
            <button
                onClick={()=>{setActiveWindow("rozliczenie")}}
            >
                Rozliczenie
            </button>
            <div className='window'>
                {window()}
            </div>

        </motion.div>
    )
}

export default Woda;
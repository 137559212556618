import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { motion } from "framer-motion";
import { wstepy } from "../../functions/motions";
import { useDispatch } from 'react-redux';
import { fetchGlosowanieKarty, fetchGlosowaniePytania } from "../../Redux/Actions";

const DodajKarte = props => {

    const { setActiveWindow, wspolnota, glosowanieRefresh } = props;

    const dispatch = useDispatch();

    const [listaPytan, setListaPytan] = useState([]);
    const [nowePytanie, setNowePytanie] = useState({tresc: "", id: null});
    const [listaNowychPytan, setListaNowychPytan] = useState([]);
    const [zakresDat, setZakresDat] = useState({dataOd: null, dataDo: null});
    const [numer, setNumer] = useState("Uchwała nr ");
    const [file, setFile] = useState(null);

    useEffect( () => {
        const lista = listaNowychPytan?.map( (pytanie, i) => (
            <div 
                key={i}
                className="pytanie"
            >
                <div>
                    {`${i+1}: ${pytanie.tresc}`}
                </div>
                <div>
                    <FontAwesomeIcon icon={faTrash} className="pointer" id={pytanie.id} onClick={()=>handleDeleteNewAsk(pytanie.id)}/>
                </div>
            </div>
        ));
        setListaPytan(lista)
    },[listaNowychPytan]);

    const handleNowePytanieChange = e => setNowePytanie({tresc: e.target.value, id: listaNowychPytan?.length + 2});
    const handleDeleteNewAsk = id => setListaNowychPytan( listaNowychPytan.filter( p => p?.id != id ) );

    const handleNowePytanieAdd = (e) => {
        e.preventDefault();
        if (nowePytanie.tresc && nowePytanie.tresc.length > 0)
            setListaNowychPytan( prevState => [...prevState, nowePytanie])
        setNowePytanie({tresc: "", id: null})
    }

    const sendFile = async (id_karty, id_wspolnoty) => {

          const formData = new FormData();
          formData.append('file', file);
          try {
            const response = await axios.post('/api/glosowanie-uchwaly/upload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            setFile(null);
            console.log(response)
            try {   
                // Wysyłamy dane na serwer
                await axios.post('/api/glosowanie-uchwaly/dodaj', {
                    id_wspolnoty: id_wspolnoty,
                    id_karty: id_karty,
                    nazwa_pliku: file.name
                }).then( response => {
                    if (response === 200){
                        glosowanieRefresh();
                    }
                })
            } catch (error) {
                console.error('Błąd podczas dodawania danych:', error);
            }

          } catch (error) {
            console.error('Error uploading file:', error);
          }

      };

    const handleDataOd = e => setZakresDat( prevState => ({...prevState, dataOd: e.target.value}) );
    const handleDataDo = e => setZakresDat( prevState => ({...prevState, dataDo: e.target.value}) );
    
    const handleSave = async e => {
        e.preventDefault();
        if (listaNowychPytan.length === 0) {
            alert("Dodaj pytania.")
        } else if (zakresDat.dataOd === null || zakresDat.dataDo === null) {
            alert("Podaj obie daty.")
        } else if (file === null) 
            alert("Wczytaj plik z uchwałą.")
        else {
            try {   
                // Wysyłamy dane na serwer
                await axios.post('/api/glosowanie-karty/dodaj', {
                    id_wspolnoty: wspolnota.id,
                    numer: numer,
                    data_od: zakresDat.dataOd,
                    data_do: zakresDat.dataDo
                }).then(response => {
                    if (response.status === 200) {
                        const id = response.data.id;
                        listaNowychPytan.forEach( (pytanie, i) => {
                            try {   
                                // Wysyłamy dane na serwer
                                axios.post('/api/glosowanie-pytania/dodaj', {
                                    id_wspolnoty: wspolnota.id,
                                    id_karty: id,
                                    tresc: pytanie.tresc
                                }).then(response => {
                                    if (response.status === 200) {
                                        setActiveWindow("lista");
                                        glosowanieRefresh();
                                    }
                                });     
                                // Po udanym dodaniu danych możesz wykonać odpowiednie akcje, np. wyczyść formularz
                              } catch (error) {
                                console.error('Błąd podczas dodawania danych:', error);
                              }
                        });
                        sendFile(id, wspolnota.id);
                    }
                });
                // Po udanym dodaniu danych możesz wykonać odpowiednie akcje, np. wyczyść formularz
              } catch (error) {
                console.error('Błąd podczas dodawania danych:', error);
              }
        }
    }

    const handleNumer = e => setNumer( e.target.value );

    const handleFileChange = (e) => setFile(e.target.files[0]);

    return (
        <motion.form 
            className="content ksiegowosc ramka-border-shadow ramka-wnetrze-ramki"
            variants={wstepy}
            initial="initial"
            animate="animate"
        >
            <p>Dodaj kartę do głosowania</p>
            {/* <p>{`Głosowanie numer: ${liczbaGlosowan + 1 }`}</p> */}
            <label htmlFor="numer">
                Tytył karty do głosowania:
            </label>
            <input 
                type="text"
                value={numer}
                onChange={handleNumer}
                id="numer"
            />
            <p>Pytania:</p>
            {listaPytan}
            <div className="nowePytanie">   
                <div className="tresc">
                    <input 
                        type="text"
                        value={nowePytanie.tresc}
                        onChange={handleNowePytanieChange}
                    />
                    <button onClick={handleNowePytanieAdd}>
                        Dodaj pytanie
                    </button>
                </div>
                <div className="daty">
                    <p>Termin głosowania:</p>
                    <label htmlFor="dataOd"> 
                        Data od:
                    </label>
                    <input
                        type="date"
                        value={zakresDat.dataOd}
                        onChange={handleDataOd}
                        id="dataOd"
                    />
                    <label htmlFor="dataDo"> 
                        Data do:
                    </label>
                    <input
                        type="date"
                        value={zakresDat.dataDo}
                        onChange={handleDataDo}
                        id="dataDo"
                    />
                    <label htmlFor="plik">
                        Plik uchwały:
                    </label>
                    <input 
                        type="file" 
                        onChange={handleFileChange} 
                    />
                </div>

                <div style={{display: "flex", justifyContent: "center" }} >
                    <button
                        onClick={handleSave}
                    >
                        Zapisz
                    </button>
                </div>
            </div>
        </motion.form>
    )
}

export default DodajKarte;
import React from "react";

import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faList, faTableList } from '@fortawesome/free-solid-svg-icons'
import { faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons'
import { faChartPie, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'

import { saldoWykrzyknik } from "../../../functions/tablice.js";

const WspolnotyListaTBody = ( props ) => {

    const { wspolnoty, saldo, tablicaPrzelewy, tablicaCzynsze } = props;

    const wiersz = wspolnoty?.map( (item,i) => {
                  
      let czy_saldo = saldo?.filter( s => s.id_wspolnoty == item.id)[0]?.saldo < 0 ? true : false;
      return (
        <li 
          key={item.id} 
          className={ i % 2 === 0 ? "g": ""} 
          style={{minHeight: "40px"}}
        >
          <div>{`${item.name} (${item.id})`}</div>
          <div>{item.nip}</div>
          <div>{item.ulica}</div>
          <div className="central">
            <NavLink 
              to={`/wspolnoty/dane?id=${item.id}`} 
              exact="true"
            >
              <FontAwesomeIcon 
                icon={faAddressCard} 
              />
            </NavLink>
          </div>
          <div className="central">
            <NavLink 
              to={`/wspolnoty/stawki/?id=${item.id}`} 
              exact="true"
            >
              <FontAwesomeIcon 
                icon={faList} 
              />
            </NavLink>
          </div>
          <div className="central">
            <NavLink 
              to={`/wspolnoty/rozliczenia/?id=${item.id}`} 
              exact="true" 
              style={{ "textDecoration": 'none'}} 
              state={
                {
                  from: 
                  {
                    tablicaPrzelewy: tablicaPrzelewy?.find( t => t?.id_wspolnoty == item.id)?.grupy,
                    tablicaCzynsze: tablicaCzynsze?.find( t => t?.id_wspolnoty == item.id)?.grupy,
                    // saldo_w: saldo?.filter( s => s?.id_wspolnoty == item.id)
                  }
                }
              }
            >
              <FontAwesomeIcon 
                icon={faHandHoldingDollar} 
              />
              { czy_saldo ? saldoWykrzyknik() : ""}
            </NavLink>
          </div>
          <div className="central">
            <NavLink 
              to={`/wspolnoty/raportZbiorowy/?id=${item.id}`} 
              exact="true" 
              style={{ "textDecoration": 'none'}} 
              state={
                {
                  from: 
                  {
                    tablicaPrzelewy: tablicaPrzelewy?.find( t => t?.id_wspolnoty == item.id)?.grupy,
                    tablicaCzynsze: tablicaCzynsze?.find( t => t?.id_wspolnoty == item.id)?.grupy,
                    idw: item.id
                    // saldo_w: saldo?.filter( s => s?.id_wspolnoty == item.id)
                  }
                }
              }
            >
              <FontAwesomeIcon 
                icon={faBook} 
              />
              { czy_saldo ? saldoWykrzyknik() : ""}
            </NavLink>
          </div>
          <div className="central">
            <NavLink 
              to={`/mieszkancy?idw=${item.id}`} 
              exact="true" 
              state={
                {
                  from: 
                  {
                    tablicaPrzelewy: tablicaPrzelewy?.find( t => t?.id_wspolnoty == item.id)?.grupy,
                    tablicaCzynsze: tablicaCzynsze,
                    idw: item.id
                    // saldo_w: saldo?.filter( s => s?.id_wspolnoty == item.id)
                  }
                }
              }
            >
              <FontAwesomeIcon 
                icon={faUserGroup} 
              />
            </NavLink>
          </div>
          <div className="central">
            <NavLink 
              to={`/wspolnoty/grupy/?id=${item.id}`} 
              exact="true" 
              style={{ "textDecoration": 'none'}} 
            >
              <FontAwesomeIcon 
                icon={faTableList} 
              />
            </NavLink>
          </div>
          <div className="central">
            <NavLink
              to={`/noty?idw=${item.id}`}
              exact="true"
              state={
                {
                  from: 
                  {
                    tablicaCzynsze: tablicaCzynsze?.find( t => t?.id_wspolnoty == item.id),
                  }
                }
              }
            >
              <FontAwesomeIcon 
                icon={faFileInvoiceDollar} 
              />
            </NavLink>
          </div>
          <div className='central'>
            <NavLink  
              to={`/wspolnoty/udzialy?id=${item.id}`}  
            >
              <FontAwesomeIcon 
                icon={faChartPie} 
                style={{color: "#307bfd",}} 
              />
            </NavLink>
          </div>
        </li>
      )
    })

    return (
      wiersz
    )
}

export default WspolnotyListaTBody;
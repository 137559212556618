import React, { useState, useEffect, useContext } from "react";
import { useSelector } from 'react-redux';
import GoBackBtn from "../../GoBackBtn";
import { dataFormatMsc, nextMonth } from '../../../functions/dataFormat';
import { wstepy } from '../../../functions/motions';
import { getSaldo_g, saldoWykrzyknik } from "../../../functions/tablice";
import { dataFormat2 } from '../../../functions/dataFormat';
import Loader from "../../Loader";
import { AuthContext } from "../../../Context/AuthProvider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'

import WspolnotaRaportZbiorowyTHead from "./WspolnotaRaportZbiorowyTHead";
import WspolnotaRaportZbiorowyTFoot from "./WspolnotaRaportZbiorowyTFoot";
import WspolnotyRaportZbiorowyTBody from "./WspolnotaRaportZbiorowyTBody";
import ZbiorowyWydrukModal from "./ZbiorowyWydrukModal";

const WspolnotaRaportZbiorowy = (props) => {

    const queryParams = new URLSearchParams(window.location.search)
    let idw = queryParams.get("id");


    const { czynszeWspolnota, przelewyWspolnota } = props;

    const { auth, user, setAuth, role, setRole, userId, cred } = useContext(AuthContext);

    const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);

    const stawki = useSelector( state => state?.stawki?.data);
    const stawkiJednorazowe = useSelector( state => state?.stawkiJednorazowe?.data);
    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const wspolnoty = useSelector(state => state?.wspolnoty?.data);

    const [czyZarzad, setCzyZarzad] = useState(null);
    const [idwZarzad, setIdwZarzad] = useState(null);
    const [showPrint, setShowPrint] = useState(false)


    useState(() => {
        const mieszkaniec = mieszkancy?.find( m => m?.id_user == userId);
        setCzyZarzad( mieszkaniec && mieszkaniec?.czy_zarzad == 1 ? true : false );
        const wspolnota = wspolnoty?.find( w => w?.id == mieszkaniec?.id_wspolnoty);
        setIdwZarzad( idw == null && mieszkaniec && mieszkaniec?.czy_zarzad == 1 ? wspolnota?.id : 0);
    },[mieszkancy, wspolnoty, idw])

    const [wspolnota, setWspolnota] = useState([]);
    const [zakresOd, setZakresOd] = useState(dataFormat2(new Date()));
    const [zakresDo, setZakresDo] = useState(dataFormat2(new Date()));
    const [listaMsc, setListaMsc] = useState([]);
    const [udzialyKontaAkt_w, setUdzialyKontaAkt_w] = useState([]);

    const [stawkiJednorazowe_w, setStawkiJednorazowe_w] = useState([]);

    useEffect( () => setWspolnota( wspolnoty?.find( w => w?.id_wspolnoty == idw !== null ? idw : idwZarzad)), [wspolnoty, idw, idwZarzad])

    useEffect( () => {
        if (idw && idw != 0 && idwZarzad === 0 ) {
            console.log('A')
            setWspolnota( wspolnoty?.find( w => w?.id == idw))
        } else if ( idw == null && idwZarzad && idwZarzad > 0) {
            console.log('B')
            setWspolnota( wspolnoty?.find( w => w?.id == idwZarzad))
        }
    }, [idw, idwZarzad, wspolnoty]);

    // console.log(wspolnoty)
    // console.log(idw)
    // console.log(idwZarzad);
    // console.log(wspolnota);

    const [loaderOn, setLoaderOn] = useState(true);

    useEffect( () => {
        if (wspolnota && listaMsc && udzialyKontaAkt_w)
          setLoaderOn(false)
    },[wspolnota, listaMsc, udzialyKontaAkt_w])

    useEffect( () => {
        setUdzialyKontaAkt_w( udzialyKontaAkt?.filter( udzial => udzial?.id_wspolnoty == idw !== null ? idw : idwZarzad))
    },[udzialyKonta, idw, idwZarzad])

    useEffect( () => {
        setListaMsc(generujZakres(zakresOd, zakresDo));
    }, [zakresOd, zakresDo, stawki, udzialyKontaAkt_w])
    
    const handleZakresOd = e => {
        if (e.target.value <= zakresDo) {
            setZakresOd(e.target.value);
            setListaMsc(generujZakres(e.target.value, zakresDo));
        }
    }

    const handleZakresDo = e => {
        if (e.target.value >= zakresOd) {
            setZakresDo(e.target.value);
            setListaMsc(generujZakres(zakresOd, e.target.value));
        }
    }

    const generujZakres = (zakresOd, zakresDo) => {
        let result = [];
        let currentDate = new Date(zakresOd);
        const lastDate = new Date(zakresDo);
      
        result.push(dataFormatMsc(zakresOd));
      
        currentDate.setDate(1);
        currentDate.setMonth(currentDate.getMonth() + 1);
      
        while (currentDate < lastDate) {
          result.push(dataFormatMsc(currentDate.toISOString().split('T')[0])); // Formatowanie na "YYYY-MM-DD"
          currentDate.setMonth(currentDate.getMonth() + 1);
        }

        return result;
    }

    const handleShowPrint = () => setShowPrint( prevState => !prevState);

    return (
        <div 
            className="content wspolnotaRozliczenie zbiorowka"
            variants={wstepy}
            initial="initial"
            animate="animate"
        >
            <Loader stan={loaderOn} />
            <h1 className="nazwa">
                Raport zbiorowy wspólnoty
            </h1>
            <h3>
                { wspolnota?.name }
            </h3>
            <div className="buttonsZbiorowka">

                <GoBackBtn />
                <input type="date" value={zakresOd} onChange={handleZakresOd}/>
                <input type="date" value={zakresDo} onChange={handleZakresDo}/>
                <FontAwesomeIcon 
                    icon={faPrint} 
                    className='pointer'
                    onClick={handleShowPrint}
                    style={{margin: "0 50px"}}
                />
            </div>

            <div style={{"height": "20px"}}></div>
            <div className="table-container zbiorowka">
                <table className="lista tabela zbiorowka" >
                    <WspolnotaRaportZbiorowyTHead 
                        listaMsc={listaMsc}
                    />

                    <WspolnotyRaportZbiorowyTBody
                        listaMsc={listaMsc}
                        idw={idw !== null ? idw : idwZarzad}
                        czynszeWspolnotaZ={czynszeWspolnota}
                        przelewyWspolnotaZ={przelewyWspolnota}
                    />
                    <WspolnotaRaportZbiorowyTFoot
                        listaMsc={listaMsc}
                        czynszeWspolnotaZ={czynszeWspolnota}
                        przelewyWspolnotaZ={przelewyWspolnota}
                    />
                </table>
            </div>

            <div style={{"height": "50px"}}></div>
            {
                showPrint &&             
                <ZbiorowyWydrukModal 
                    handleShowPrint={handleShowPrint}
                    listaMsc={listaMsc}
                    wspolnota={wspolnota}
                    idw={idw}
                />  
            }
        </div>
    ) 

}

export default WspolnotaRaportZbiorowy;
import React from "react";


const SelectWspolnoty = ( props ) => {

  const {wybranaWspolnota, setWybranaWspolnota, wspolnoty, setWybranyUdzialKonto, setWybranyMieszkaniec} = props;

  const onChangeHandle = (e) => {
    const idw = e.target.value;
    setWybranaWspolnota(wspolnoty?.find( w => w.id == idw));
    setWybranyUdzialKonto(null);
    setWybranyMieszkaniec(null);
  } 

  const listaWspolnot = wspolnoty?.map ( (el, i) => (
    <option key={i} value={el.id}>
        {el.name}
    </option>
  ));

  return (
    <div className='listaWspolnot'>
      <select 
        onChange={onChangeHandle}
        value={wybranaWspolnota?.id}
      >
          <option value="0">
              Wybierz wspólnotę:
          </option> 
          {listaWspolnot}
      </select>
    </div>
  ) 
}

export default SelectWspolnoty;
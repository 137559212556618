import React, { useEffect, useRef, useState } from "react";
import { data_z_dniem, dataFormat } from "../../../functions/dataFormat";  
import { adresPro } from "../../../functions/ulice";
import { useReactToPrint } from 'react-to-print';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import emailjs from '@emailjs/browser';

const RozliczenieWydrukButtons = props => {

    const { showPrint, componentRef } = props;

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Visitor Pass',
        onAfterPrint: () => console.log('Printed PDF successfully!'),
    });
        
    const pdfRef = useRef(null);

    const handleGenerateAndSendPDF = async () => {
        const element = pdfRef.current;
        const canvas = await html2canvas(element);
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'pt',
            format: 'a4'
        });
        pdf.addImage(imgData, 'PNG', 0, 0);
        const pdfBlob = pdf.output('blob');

        // Konwertuj PDF blob do Base64
        const reader = new FileReader();
        reader.readAsDataURL(pdfBlob);
        reader.onloadend = function () {
            const base64data = reader.result.split(',')[1]; // Pobiera Base64 bez nagłówka
            console.log(base64data)
            // Wysyłanie e-maila z załącznikiem
            emailjs.send('service_d6ugz9r', 'template_rgzgc5s', {
                to_email: 'recipient@example.com',
                from_name: 'Your Name',
                message: 'W załączeniu PDF z wygenerowanego dokumentu.',
                attachment: base64data,
                attachment_name: 'wydruk.pdf'
            }, 'Sf5FCcGAApPYZnnXi')
            .then((response) => {
                console.log('Sukces!', response.status, response.text);
            })
            .catch((error) => {
                console.error('Błąd podczas wysyłania e-maila:', error);
            });
        };
    };

    return (
        <div className="buttons">
            <button 
                onClick={handlePrint}
            >
                Drukuj
            </button>
            <button
                onClick={handleGenerateAndSendPDF}
                disabled
            >
                Wyślij
            </button>
            <button
                onClick={showPrint}
            >
                Anuluj
            </button>
        </div>
    )
}

export default RozliczenieWydrukButtons;
import React, { useEffect, useState } from 'react';
import { dataFormat, strefa, zamienTekstNaDate } from '../../../functions/dataFormat';
import { zl } from '../../../functions/utilities';


const RozliczenieMieszkaniecNaliczeniaRazem = props => {

    const { licznik, tablicaCzynsze_ug, lokal, czyWydruk } = props;

    const [lista, setLista] = useState([]);
    const [naliczonaCiepla, setNaliczonaCiepla] = useState(0);
    const [nalizonaZimna, setNaliczonaZimna] = useState(0);
    const [naliczonyAboCw, setNaliczonyAboCw] = useState(0);

    useEffect( () => setLista( generujDaty(licznik) ), [licznik, tablicaCzynsze_ug]);

    useEffect( () => {
        let sumaCiepla = 0;
        let sumaZimna = 0;
        let sumaAboCw = 0;
        
        lista.forEach( l => {
            const udzial = tablicaCzynsze_ug?.find( t => t?.msc == l);
            if (udzial) {
                sumaCiepla += udzial?.ciepla_woda;
                sumaZimna += udzial?.zimna_woda;
                sumaAboCw += udzial?.abonament_cw;
            }
        });

        setNaliczonaCiepla(sumaCiepla);
        setNaliczonaZimna(sumaZimna);
        setNaliczonyAboCw(sumaAboCw);

    },[lista,tablicaCzynsze_ug]);

    const generujDaty = (licznik) => {
        const { okres_poczatek, okres_koniec } = licznik;
      
        const startDate = new Date(okres_poczatek);
        const endDate = new Date(okres_koniec);
      
        const result = [];
        const current = new Date(startDate);
      
        while (current <= endDate) {
          const month = String(current.getMonth() + 1).padStart(2, '0'); // Dodanie zer dla jednocyfrowych miesięcy
          const year = current.getFullYear();
          result.push(`${month}-${year}`);
      
          // Przejdź do następnego miesiąca
          current.setMonth(current.getMonth() + 1);
        }
      
        return result;
    };
    
    const saldo = (licznik?.zimna_koszt - nalizonaZimna) + ((licznik?.abonament_cw_cena * lokal?.metraz) - naliczonyAboCw) + (licznik?.ciepla_koszt - naliczonaCiepla);

    return (
        <table
            style={ czyWydruk ? {fontSize: "12px"} : {}}
        >
            <thead>
                <tr>
                    <th>
                        
                    </th>
                    <th style={{textAlign: "center"}}>
                        Naliczone <br/>stawki za okres 
                    </th>
                    <th style={{textAlign: "center"}}>
                        koszt zużycia<br/>w okresie <br/>rozliczeniowym
                    </th>
                    <th style={{textAlign: "center"}}>
                        rozliczenie
                    </th>
                    <th>
                        razem
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        zimna woda
                    </td>
                    <td style={{textAlign: "center"}}>
                        {zl(nalizonaZimna)}
                    </td>
                    <td style={{textAlign: "center"}}>
                        {zl(licznik?.zimna_koszt)}
                    </td>
                    <td style={{textAlign: "center"}}>
                        {zl(licznik?.zimna_koszt - nalizonaZimna)}
                    </td>
                    <td className='saldo'>
                        { saldo >= 0 ? "Nadpłata:" : "Niedopłata:" }
                    </td>
                </tr>
                <tr>
                    <td>
                        abonament cw
                    </td>
                    <td style={{textAlign: "center"}}>
                        {zl(naliczonyAboCw)}
                    </td>
                    <td style={{textAlign: "center"}}>
                        {zl(licznik?.abonament_cw_cena * lokal?.metraz)}    
                    </td>
                    <td style={{textAlign: "center"}}>
                        {zl((licznik?.abonament_cw_cena * lokal?.metraz) - naliczonyAboCw)}
                    </td>
                    <td className='saldo'>
                        { zl(saldo) } 
                    </td>
                </tr>
                <tr>
                    <td>
                        ciepła woda
                    </td>
                    <td style={{textAlign: "center"}}>
                        {zl(naliczonaCiepla)}
                    </td>
                    <td style={{textAlign: "center"}}>
                        {zl(licznik?.ciepla_koszt)}
                    </td>
                    <td style={{textAlign: "center"}}>
                        {zl(licznik?.ciepla_koszt - naliczonaCiepla)}
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default RozliczenieMieszkaniecNaliczeniaRazem;